// LeadsTable.js
import React from 'react';
import { Table, Tag, Typography, Button, Empty } from 'antd';
import { EyeOutlined } from '@ant-design/icons';
import {formatDateTime} from "../../utils/dateUtils";
import {formatRupee} from "../../utils/utils";

const { Paragraph } = Typography;

const LeadsTable = ({ data, loading, pagination, onPageChange, onViewLead }) => {
  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Customer Name',
      dataIndex: 'customer_name',
      key: 'customer_name',
      render: text => <span ellipsis={{ tooltip: text }}>{text}</span>,
    },
    {
      title: 'Phone Number',
      dataIndex: 'mobile_number',
      key: 'mobile_number',
    },
    {
      title: 'Status',
      dataIndex: 'status_display',
      key: 'status_display',
      align: 'center',
    },
    {
      title: 'Follow-up Date',
      dataIndex: 'follow_up_date',
      key: 'follow_up_date',
      render: date => date ? formatDateTime(date) : '—',
    },
      {
      title: 'Assignee',
      dataIndex: 'assigned_to_name',
      key: 'assigned_to_name',
    },
      {
      title: 'Est. Value',
      dataIndex: 'estimated_revenue',
      key: 'estimated_revenue',
        align: 'right',
      render: value => value ? formatRupee(value) : '—',
    },
      {
      title: 'Source',
      dataIndex: 'source_name',
      key: 'source_name',
        align: 'center',
    },
      {
      title: 'Branch',
      dataIndex: 'branch_name',
      key: 'branch_name',
        align: 'center',
    },
      {
      title: 'Company',
      dataIndex: 'company_code',
      key: 'company_code',
        align: 'center',
    },

  ];

  const showPaginationTotal = (total, range) => {
    return `Showing ${range[0]}-${range[1]} of ${total} records`;
  };

  return (
    <Table
      columns={columns}
      dataSource={data}
      rowKey="id"
      pagination={{
        current: pagination.current,
        pageSize: pagination.pageSize,
        total: pagination.total,
        showTotal: showPaginationTotal,
        onChange: onPageChange,
      }}
      loading={loading}
      size="small"
      scroll={{ x: 'max-content' }}
      locale={{
        emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No leads available." />,
      }}
      onRow={(record) => ({
                onClick: () => onViewLead(record)
            })}
    />
  );
};

export default LeadsTable;
