import {getAuthToken} from "../../utils/authUtils";
import axios from "axios";
import config from "../../config";

export const fetchMarketingContents = async (filters = {}) => {
    const token = getAuthToken();
    let url = `${config.inxOmsApiUrl}/crm/api/campaign_contents/`;

    // Building query parameters based on filters object
    const params = new URLSearchParams();
    for (const [key, value] of Object.entries(filters)) {
        if (value) params.append(key, value);
    }
    url += `?${params.toString()}`;

    try {
        const response = await axios.get(url, {
            headers: { Authorization: `Token ${token}` }
        });
        if (response.data && response.data) {
            return response.data;  // Assuming the API returns an object with a 'results' array
        } else {
            throw new Error("Invalid response structure");
        }
    } catch (error) {
        console.error('Error fetching marketing contents:', error);
        throw new Error('Failed to fetch contents: ' + error.message);
    }
};



export const fetchMarketingCampaigns = async (params) => {
  // 'params' is a plain object with page, page_size, status, serial_number, etc.
  const token = getAuthToken();
  try {
    const response = await axios.get(`${config.inxOmsApiUrl}/crm/api/marketing_campaigns/`, {
      headers: { Authorization: `Token ${token}` },
      params, // pass the object
    });
    return response.data;
  } catch (error) {
    // handle error
    throw error;
  }
};


export const fetchEvents = async (params) => {
  // 'params' is a plain object with page, page_size, status, serial_number, etc.
  const token = getAuthToken();
  try {
    const response = await axios.get(`${config.inxOmsApiUrl}/crm/api/communication_events/`, {
      headers: { Authorization: `Token ${token}` },
      params, // pass the object
    });
    return response.data;
  } catch (error) {
    // handle error
    throw error;
  }
};



export const fetchCommunicationLogs = async (params) => {
  // 'params' is a plain object with page, page_size, status, serial_number, etc.
  const token = getAuthToken();
  try {
    const response = await axios.get(`${config.inxOmsApiUrl}/crm/api/communication-logs/`, {
      headers: { Authorization: `Token ${token}` },
      params, // pass the object
    });
    // console.log("Communication logs response", response.data);
    return response.data;
  } catch (error) {
    // handle error
    throw error;
  }
};


// Upload Customer List against an Event
export const uploadEventCustomerList = async (eventId, file) => {
    const token = getAuthToken();
    const formData = new FormData();
    formData.append('file', file);

    try {
        const response = await axios.post(
            `${config.inxOmsApiUrl}/crm/api/events/${eventId}/upload-customers/`, // ✅ Correct `eventId`
            formData,
            {
                headers: {
                    Authorization: `Token ${token}`,
                    'Content-Type': 'multipart/form-data'
                }
            }
        );
        return response.data;
    } catch (error) {
        console.error('Error uploading customer list:', error);
        throw error;
    }
};


export const fetchLeadsApi = async (params) => {
  // 'params' is a plain object with page, page_size, status, serial_number, etc.
  const token = getAuthToken();
  try {
    const response = await axios.get(`${config.inxOmsApiUrl}/crm/api/leads/`, {
      headers: { Authorization: `Token ${token}` },
      params, // pass the object
    });
    return response.data;
  } catch (error) {
    // handle error
    throw error;
  }
};

export const fetchLeadInteractions = async (leadId, { page, pageSize }) => {
  const token = getAuthToken();
  try {
    const response = await axios.get(`${config.inxOmsApiUrl}/crm/api/lead-interactions/`, {
      headers: { Authorization: `Token ${token}` },
      params: { lead: leadId, page: page, pageSize: pageSize }
    });
    return response.data;
  } catch (error) {
    console.error("Failed to fetch lead interactions:", error);
    throw error;  // Ensure that you handle this error in the component where you call this function
  }
};


// Function to register a new customer
export const createLeadApi = async (payload) => {
    const token = getAuthToken();
    try {
        const response = await axios.post(`${config.inxOmsApiUrl}/crm/api/leads/create_lead/`, payload, {
            headers: { Authorization: `Token ${token}` }
        });
        console.log(response);
        return response.data;
    } catch (error) {
        console.error('Error registering customer:', error);
        throw error.response ? error.response.data : error;
    }
};

export const addLeadInteraction = async (leadId, interactionData) => {
    const token = getAuthToken(); // Assuming you have a function to retrieve the auth token
    try {
        const response = await axios.post(`${config.inxOmsApiUrl}/crm/api/leads/${leadId}/add_interaction/`, interactionData, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            }
        });
        console.log("Add Interaction:", response);
        return response.data;
    } catch (error) {
        console.error('Failed to update lead interaction:', error);
        throw new Error('Failed to update the follow-up details.');
    }
};

// Function to fetch lead details from the server
export const fetchLeadDetails = async (leadId) => {
    const token = getAuthToken(); // Assuming you have a function to retrieve the auth token
  try {
    const response = await axios.get(`${config.inxOmsApiUrl}/crm/api/leads/${leadId}/`,
        {
             headers: {
                'Authorization': `Token ${token}`
            }
        });
    return response.data;  // Assuming the server responds with the lead details directly
  } catch (error) {
    console.error("Failed to fetch lead details:", error);
    throw error;  // Handle errors as needed
  }
};

// crmApis.js
export const executeEvent = async (eventId) => {
    const token = getAuthToken(); // Assuming you have a function to retrieve the auth token
    try {
        const response = await fetch(`${config.inxOmsApiUrl}/crm/api/events/${eventId}/execute_event/`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Token ${token}`
        }
    });
    return response.data;
    }catch (error) {
    console.error("Failed to fetch lead details:", error);
    throw error;  // Handle errors as needed
  }

};

export const scheduleEvent = async (eventId) => {
    const token = getAuthToken(); // Retrieve the authentication token
    try {
        const response = await axios.post(`${config.inxOmsApiUrl}/crm/api/events/${eventId}/schedule_event/`, {}, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`
            }
        });
        if (response.status === 200) {
            console.log("Event scheduled successfully:", response.data);
            return response.data;
        } else {
            throw new Error('Failed to schedule event');
        }
    } catch (error) {
        console.error("Failed to schedule event:", error);
        throw error; // Rethrow the error for further handling, if necessary
    }
};



export const updateLead = async (leadId, payload) => {
  const token = getAuthToken();
  try {
    const response = await axios.patch(
      `${config.inxOmsApiUrl}/crm/api/leads/${leadId}/`,
      payload,
      { headers: { Authorization: `Token ${token}` } }
    );
    return response.data;
  } catch (error) {
    console.error("Error updating lead:", error);
    throw error;
  }
};

export const updateLeadAssignee = async (leadId, assigneeId) => {
    const token = getAuthToken();
    try {
        const response = await axios.patch(
            `${config.inxOmsApiUrl}/crm/api/leads/${leadId}/`,
            { assigned_to: assigneeId },
            { headers: { Authorization: `Token ${token}` } }
        );
        return response.data;
    } catch (error) {
        console.error("Error updating lead assignee:", error);
        throw error;
    }
};



export const fetchUsers = async () => {
  const token = getAuthToken();
  try {
    const response = await axios.get(`${config.inxOmsApiUrl}/users/api/users/`, {
      headers: { Authorization: `Token ${token}` },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching users:", error);
    throw error;
  }
};


// Function to create a new event
export const createEvent = async (eventData) => {
    const token = getAuthToken();
    try {
        const response = await axios.post(`${config.inxOmsApiUrl}/crm/api/communication_events/`, eventData, {
            headers: {
                Authorization: `Token ${token}`
            }
        });
        console.log("Event created:", response);
        return response.data;
    } catch (error) {
        console.error('Error creating event:', error);
        throw error.response ? error.response.data : error;  // Rethrow with more specific error if available
    }
};

// Function to update an existing event
export const updateEvent = async (eventData) => {
    const token = getAuthToken();
    const { id, ...updateData } = eventData;  // Destructure to separate id from other data
    try {
        const response = await axios.put(`${config.inxOmsApiUrl}/crm/api/communication_events/${id}/`, updateData, {
            headers: {
                Authorization: `Token ${token}`
            }
        });
        console.log("Event updated:", response);
        return response.data;
    } catch (error) {
        console.error('Error updating event:', error);
        throw error.response ? error.response.data : error;  // Rethrow with more specific error if available
    }
};


// Function to create a new event
export const createCampaignContent = async (eventData) => {
    const token = getAuthToken();
    try {
        const response = await axios.post(`${config.inxOmsApiUrl}/crm/api/campaign_contents/`, eventData, {
            headers: {
                Authorization: `Token ${token}`
            }
        });
        console.log("Event created:", response);
        return response.data;
    } catch (error) {
        console.error('Error creating event:', error);
        throw error.response ? error.response.data : error;  // Rethrow with more specific error if available
    }
};

// Function to update an existing event
export const updateCampaignContent = async (eventData) => {
    const token = getAuthToken();
    const { id, ...updateData } = eventData;  // Destructure to separate id from other data
    try {
        const response = await axios.put(`${config.inxOmsApiUrl}/crm/api/campaign_contents/${id}/`, updateData, {
            headers: {
                Authorization: `Token ${token}`
            }
        });
        console.log("Event updated:", response);
        return response.data;
    } catch (error) {
        console.error('Error updating event:', error);
        throw error.response ? error.response.data : error;  // Rethrow with more specific error if available
    }
};