import React, { useEffect, useState } from 'react';
import { Button, Form, Input, DatePicker, Select, Modal, notification } from 'antd';
import { createEvent, updateEvent, fetchMarketingCampaigns, fetchMarketingContents } from './crmApis';
import moment from 'moment';

const { Option } = Select;

const EventFormModal = ({ visible, onClose, event, refreshEvents }) => {
    const [form] = Form.useForm();
    const [campaigns, setCampaigns] = useState([]);
    const [contents, setContents] = useState([]);

    useEffect(() => {
         if (form) {
             if (visible) {
                 loadCampaigns();
                 if (event) {
                     loadContents(event.campaign);
                     form.setFieldsValue({
                         ...event,
                         scheduled_date: event.scheduled_date ? moment(event.scheduled_date) : null,
                     });
                 } else {
                     form.resetFields();
                 }
             }
         }
    }, [event, form, visible]);

    const loadCampaigns = async () => {
        try {
            const data = await fetchMarketingCampaigns();
            console.log("Campaign data", data.results);
            setCampaigns(data.results || []); // Ensure data is always an array
        } catch (error) {
            notification.error({ message: 'Failed to load campaigns', description: error.message });
            setCampaigns([]); // Ensure campaigns is reset to an empty array on error
        }
    };

const loadContents = async (campaignId) => {
    if (!campaignId) {
        setContents([]);
        return;
    }
    try {
        // Directly fetch the contents based on the campaign ID
        const data = await fetchMarketingContents({ campaign: campaignId });
        // Since fetchMarketingContents now handles errors internally and always returns an array
        setContents(data.results);
    } catch (error) {
        console.error('Failed to load contents:', error);
        notification.error({
            message: 'Failed to load contents',
            description: `Error: ${error.message || "Unknown error"}`
        });
        setContents([]); // Ensure the contents are reset on error
    }
};



    const handleCampaignChange = (campaignId) => {
        form.setFieldsValue({ content: undefined }); // Reset content field
        loadContents(campaignId);
    };

    const handleSubmit = async (values) => {
    try {
        const payload = { ...values, content: values.content }; // Ensure content is included
        if (event) {
            await updateEvent({ ...payload, id: event.id });
            notification.success({ message: 'Event updated successfully!' });
        } else {
            console.log("Payload", payload);
            await createEvent(payload);
            notification.success({ message: 'Event created successfully!' });
        }
        refreshEvents();
        onClose();
    } catch (error) {
        notification.error({ message: 'Operation failed', description: error.response.data || 'Unknown error' });
    }
};


    return (
        <Modal
            title={event ? "Edit Event" : "Create New Event"}
            open={visible}
            onOk={() => form.submit()}
            onCancel={onClose}
            footer={[
                <Button key="back" onClick={onClose}>Cancel</Button>,
                <Button key="submit" type="primary" onClick={() => form.submit()}>{event ? "Update" : "Create"}</Button>,
            ]}
        >
            <Form form={form} layout="vertical" onFinish={handleSubmit}>
                <Form.Item name="event_name" label="Event Name" rules={[{ required: true, message: 'Please input the event name!' }]}>
                    <Input />
                </Form.Item>
                <Form.Item name="campaign" label="Campaign" rules={[{ required: true, message: 'Please select a campaign!' }]}>
                    <Select placeholder="Select a campaign" onChange={handleCampaignChange}>
                        {campaigns.map(campaign => (<Option key={campaign.id} value={campaign.id}>{campaign.name}</Option>))}
                    </Select>
                </Form.Item>
                <Form.Item name="content" label="Content" rules={[{ required: true, message: 'Please select content!' }]}>
                    <Select placeholder="Select content" disabled={!form.getFieldValue('campaign')}>
                        {contents.map(content => (<Option key={content.id} value={content.id}>{content.title}</Option>))}
                    </Select>
                </Form.Item>
                <Form.Item name="scheduled_date" label="Scheduled Date" rules={[{ required: true, message: 'Please select a date!' }]}>
                    <DatePicker showTime />
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default EventFormModal;
