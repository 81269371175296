import { getAuthToken } from "../../utils/authUtils";
import axios from "axios";
import config from "../../config";

// Fetch Task Progress
export const fetchTaskProgress = async (taskId) => {
    const token = getAuthToken();
    console.log("API Called to fetch task progress", taskId);
    try {
        const response = await axios.get(
            `${config.inxOmsApiUrl}/common/api/task-progress-celery/${taskId}/`,
            {
                headers: {
                    Authorization: `Token ${token}`,
                },
            }
        );
        console.log("response: ", response.data);
        return response.data;
    } catch (error) {
        // Handle different types of errors gracefully
        if (axios.isAxiosError(error)) {
            if (!error.response) {
                // Network error (server down, no internet, etc.)
                console.error("Network error: Unable to reach server.");
                return { error: "Network error: Please check your internet connection." };
            }

            if (error.response.status === 404) {
                // Task not found
                console.warn(`Task with ID ${taskId} not found.`);
                return { error: "Task not found. Please check the Task ID." };
            }

            if (error.response.status === 401) {
                // Unauthorized (Token expired or invalid)
                console.warn("Unauthorized access: Token may be invalid or expired.");
                return { error: "Unauthorized access. Please log in again." };
            }

            // General API error
            console.error(`API error: ${error.response.status} - ${error.response.data.error || "Unknown error"}`);
            return { error: `Error: ${error.response.data.error || "Something went wrong"}` };
        }

        // Unexpected error (non-Axios related)
        console.error("Unexpected error:", error);
        return { error: "An unexpected error occurred. Please try again later." };
    }
};
