import React, { useCallback, useEffect, useState } from 'react';
import { Button, Col, Empty, Input, Row, Select, Table, notification } from 'antd';
import CampaignContentFormModal from './CampaignContentFormModal';
import { fetchMarketingContents } from "./crmApis";

const { Option } = Select;

const CampaignContentList = () => {
    const [contents, setContents] = useState([]);
    const [selectedContent, setSelectedContent] = useState(null);
    const [modalVisible, setModalVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const [filters, setFilters] = useState({ messageType: '', campaign: '' });
    const [pagination, setPagination] = useState({ current: 1, pageSize: 10, total: 0 });

    useEffect(() => {
        loadContents();
    }, [filters, pagination.current, pagination.pageSize]);

    const loadContents = useCallback(async () => {
        setLoading(true);
        try {
            const data = await fetchMarketingContents({
                ...filters,
                page: pagination.current,
                pageSize: pagination.pageSize
            });
            setContents(data.results);
            setPagination(prev => ({ ...prev, total: data.total }));
        } catch (error) {
            console.error('Failed to load contents:', error);
            notification.error({ message: 'Error loading contents', description: error.toString() });
        } finally {
            setLoading(false);
        }
    }, [filters, pagination.current, pagination.pageSize]);

    const handlePageChange = (page, pageSize) => {
        setPagination({ current: page, pageSize });
    };

    const handleFilterChange = (type) => (value) => {
        setFilters(prev => ({ ...prev, [type]: value }));
        setPagination({ current: 1, pageSize: pagination.pageSize }); // Reset to first page with new filters
    };

    const columns = [
        { title: '#', dataIndex: 'id', key: 'id' },
        { title: 'Title', dataIndex: 'title', key: 'title' },
        { title: 'Campaign', dataIndex: 'campaign_name', key: 'campaign_name' },
        { title: 'Message Type', dataIndex: 'message_type', align: 'center', key: 'message_type' },
        { title: 'Template', dataIndex: 'template_id', key: 'template_id' },
        { title: 'Is Personalized', dataIndex: 'is_personalized', key: 'is_personalized', align: 'center', render: text => text ? 'Yes' : 'No' },
    ];

    const handleEditContent = (content) => {
        setSelectedContent(content);
        setModalVisible(true);
    };

    return (
        <div style={{ padding: 24 }}>
            <Row gutter={[16, 16]}>
                <Col span={8}>
                    <Select placeholder="Filter by Message Type" style={{ width: '100%' }} onChange={handleFilterChange('messageType')} allowClear>
                        <Option value="email">Email</Option>
                        <Option value="whatsapp">WhatsApp</Option>
                        <Option value="sms">SMS</Option>
                        <Option value="social_media">Social Media</Option>
                    </Select>
                </Col>
                <Col span={8}>
                    <Input placeholder="Search by Campaign" onChange={(e) => handleFilterChange('campaign')(e.target.value)} />
                </Col>
                <Col span={8}>
                    <Button type="primary" onClick={() => setModalVisible(true)}>
                        Create New Content
                    </Button>
                </Col>
            </Row>
            <Table
                columns={columns}
                dataSource={contents}
                rowKey="id"
                loading={loading}
                pagination={{
                    current: pagination.current,
                    pageSize: pagination.pageSize,
                    total: pagination.total,
                    showTotal: total => `Total ${total} items`,
                    onChange: handlePageChange
                }}
                onRow={(record) => ({
                    onClick: () => handleEditContent(record)
                })}
                size="small"
                scroll={{x: 'max-content'}}
                locale={{
                    emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No records available."/>
                }}
            />
            {modalVisible && (
                <CampaignContentFormModal
                    visible={modalVisible}
                    onClose={() => setModalVisible(false)}
                    content={selectedContent}
                    refreshContents={loadContents}
                />
            )}
        </div>
    );
};

export default CampaignContentList;
