import React, {useEffect, useState} from 'react';
import {Badge, Button, Descriptions, Drawer, Empty, Popconfirm, Spin, Table, Tag, Tooltip, Typography} from 'antd';
import {
    fetchSaleOrderDetailsApi,
    fetchSerialNumbersApi,
    recordSerialNumbersApi,
    updateSaleOrderCustomerApi
} from './saleOrdersApi';
import {formatRupee, truncateText} from '../../utils/utils';
import {formatDate, formatDateTime} from "../../utils/dateUtils";
import OrderActionsForDetailsDrawer from "./OrderActionsForDetailsDrawer";
import {EditOutlined, QrcodeOutlined} from "@ant-design/icons";
import SerialNumberInput from "../common/SerialNumberInput";
import SerialNumberInputModal from "./SerialNumberInputModal";
import NewPaymentForm from "../customer_payments/NewPaymentForm";
import {confirmPaymentApi, fetchSaleOrderPaymentsApi} from "../customer_payments/customerPaymentsApi";
import {PAYMENT_STATUS_COLORS} from "../common/paymentStatusColors";
import UpdateCustomerDetails from "./UpdateCustomerDetails";
import {getAuthToken} from "../../utils/authUtils";

const {Text, Title} = Typography;
const token = getAuthToken();
const SaleOrderDetailsDrawer = ({
                                    visible,
                                    orderId,
                                    onClose,
                                    onEditOrder,
                                    setErrorMessage,
                                    setSuccessMessage,
                                    refreshOrders
                                }) => {
    const [orderDetails, setOrderDetails] = useState(null);
    const [loading, setLoading] = useState(false);
    const [generatingInvoice, setGeneratingInvoice] = useState(false);


    const [serialNumbers, setSerialNumbers] = useState({});
    const [serialLoading, setSerialLoading] = useState({});
    const [serialFetched, setSerialFetched] = useState({});
    const [payments, setPayments] = useState([]);

    const [showSerialInput, setShowSerialInput] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);

    const [selectedSerialNumbers, setSelectedSerialNumbers] = useState([]);

    const [serialModalVisible, setSerialModalVisible] = useState(false);
    const [currentItemId, setCurrentItemId] = useState(null);
    const [currentItemDetails, setCurrentItemDetails] = useState(null);


    // State variables for the conditions
    const [isAutomaticallyApproved, setIsAutomaticallyApproved] = useState(false);
    const [isManuallyApproved, setIsManuallyApproved] = useState(false);
    const [isRejected, setIsRejected] = useState(false);
    const [needsApproval, setNeedsApproval] = useState(false);

    const [paymentFormVisible, setPaymentFormVisible] = useState(false);

    const [customerModalVisible, setCustomerModalVisible] = useState(false);

    const handleCustomerChange = async (customerData) => {
        try {
            await updateSaleOrderCustomerApi(orderId, customerData);
            setSuccessMessage('Customer updated successfully');
            await refreshOrderDetails();
            await refreshOrders();
        } catch (error) {
            setErrorMessage('Error updating customer:', error);
        }
    };


// Function to handle payment confirmation
    const handleConfirmPayment = async (paymentId, paymentMethod) => {
        if (paymentMethod === 'credit') {
            setErrorMessage('Payments made via credit cannot be confirmed.');
            return;
        }
        try {
            await confirmPaymentApi(paymentId); // Call the API to confirm the payment
            setSuccessMessage('Payment confirmed successfully!');

            await refreshOrderDetails();
            await refreshOrders();
            // Refresh your payment list or data here
        } catch (error) {
            setErrorMessage('Failed to confirm payment');
        }
    };

    useEffect(() => {
        const fetchDetails = async () => {
            if (!orderId) return;
            setLoading(true);
            try {
                const data = await fetchSaleOrderDetailsApi(orderId);
                console.log("data", data);
                setOrderDetails(data);
                const paymentsData = await fetchSaleOrderPaymentsApi(orderId);
                setPayments(paymentsData);
                // Define conditions based on the fetched data
                if (data) {
                    const autoApproved = data.is_approved;
                    const manuallyApproved = data.approved_by && data.status !== "rejected";
                    const rejected = data.status === "rejected";
                    const approvalNeeded = data.status === "draft" || data.status === "awaiting_approval";

                    // Update state variables
                    setIsAutomaticallyApproved(autoApproved);
                    setIsManuallyApproved(manuallyApproved);
                    setIsRejected(rejected);
                    setNeedsApproval(approvalNeeded);
                }
            } catch (error) {
                setErrorMessage('Failed to load sale order details');
            } finally {
                setLoading(false);
            }
        };

        if (visible) fetchDetails();
    }, [visible, orderId]);

    const refreshOrderDetails = async () => {
        try {
            const updatedData = await fetchSaleOrderDetailsApi(orderId);
            setOrderDetails(updatedData);
            const paymentsData = await fetchSaleOrderPaymentsApi(orderId);
            setPayments(paymentsData);
            setSuccessMessage('Order details updated.');
        } catch (error) {
            setErrorMessage('Failed to refresh order details');
        }
    };


    const paymentColumns = [
        {
            title: 'Date',
            dataIndex: 'payment_date',
            key: 'payment_date',
            render: text => formatDate(text),
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            key: 'amount',
            align: 'right',
            render: amount => formatRupee(amount),
        },
        {
            title: 'Method',
            align: 'center',
            dataIndex: 'payment_method_display',
            key: 'payment_method_display',
        },
        {
            title: 'Status',
            dataIndex: 'payment_status_display',
            key: 'payment_status_display',
            render: (status, record) => (
                <Tag color={PAYMENT_STATUS_COLORS[record.payment_status]}>
                    {status}
                </Tag>
            ),
        },
        {
            title: 'Transaction #',
            align: 'center',
            dataIndex: 'transaction_number',
            key: 'transaction_number',
        },
        {
            title: 'Account',
            align: 'center',
            dataIndex: 'account_name',
            key: 'account_name',
        },
        {
            title: 'Action',
            key: 'action',
            align: 'center',
            render: (_, record) => (
                record.payment_status === 'draft' || record.payment_status === 'requested' ? (
                    <Popconfirm
                        title="Are you sure to confirm this payment?"
                        onConfirm={() => handleConfirmPayment(record.id, record.payment_method)}
                        okText="Yes"
                        cancelText="No"
                    >
                        <Button type="primary" size="small">Confirm</Button>
                    </Popconfirm>
                ) : null
            ),
        },
    ];


    const fetchSerialNumbers = async (itemId, force = false) => {
        // Only skip if !force and we have already fetched or are loading
        if (!force && (serialFetched[itemId] || serialLoading[itemId])) {
            return;
        }

        setSerialLoading((prev) => ({...prev, [itemId]: true}));
        try {
            const data = await fetchSerialNumbersApi(itemId);
            const serialNumberValues = data.map((serial) => serial.serial_number_value); // Map only serial_number_value

            setSerialNumbers((prev) => ({...prev, [itemId]: data}));
            setSerialFetched((prev) => ({...prev, [itemId]: true}));
            setSelectedSerialNumbers((prev) => ({...prev, [itemId]: serialNumberValues}));
            console.log("selectedSerialNumbers", selectedSerialNumbers);
        } catch (error) {
            setErrorMessage(`Failed to load serial numbers for item ${itemId}`);
        } finally {
            setSerialLoading((prev) => ({...prev, [itemId]: false}));
        }
    };

    const handleSerialIconClick = async (record) => {
        // Check if the order's status is not 'ready_to_execute'
        if (orderDetails.status !== 'ready_to_execute') {
            setErrorMessage("Serial numbers can only be recorded when the order status is 'Ready to Execute'.");
            return;
        }

        console.log(`Icon clicked for item ID: ${record.id}`);
        const saleOrderItemId = record.id; // numeric ID
        setCurrentItemDetails({
            id: record.id,
            SrItem:record.item,
            itemCode: record.item_code,
            quantity: record.quantity,
        });
        setCurrentItemId(saleOrderItemId);
        console.log(`Updated Current item ID: ${saleOrderItemId}`);
        setSerialModalVisible(true);
        if (!serialFetched[saleOrderItemId]) { // Only fetch if not already fetched
            try {
                const serials = await fetchSerialNumbersApi(saleOrderItemId);
                setSerialNumbers((prev) => ({...prev, [saleOrderItemId]: serials}));
                setSerialFetched((prev) => ({...prev, [saleOrderItemId]: true}));
            } catch (error) {
                setErrorMessage(error.error || 'Failed to load serial numbers');
                console.error('Error fetching serial numbers:', error);
            }
        }

    };

    const handleSerialNumbersSubmit = async (saleOrderItemId, enteredSerials) => {
        try {
            const result = await recordSerialNumbersApi(saleOrderItemId, enteredSerials);
            console.log('Success:', result);
            setSuccessMessage('Serial numbers recorded successfully');
            // Refresh order details to reflect the new serial numbers
            // Refresh serial numbers for the specific item
            const serials = await fetchSerialNumbersApi(saleOrderItemId);
            setSerialNumbers((prev) => ({...prev, [saleOrderItemId]: serials}));
            setSerialFetched((prev) => ({...prev, [saleOrderItemId]: true}));
            const updatedData = await fetchSaleOrderDetailsApi(orderId);
            setOrderDetails(updatedData);
        } catch (error) {
            setErrorMessage(error?.error || 'Failed to record serial numbers');
        }
    };

    const handleEditOrder = (orderData) => {
        onEditOrder(orderData);
        onClose(); // Close the drawer after initiating edit
    };


    const columns = [
        {
            title: 'Item Details',
            key: 'item_details',
            render: (_, record) => (
                <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '12px',
                    padding: '4px 0',
                    whiteSpace: 'nowrap'
                }}>
                    {/* Item Code and Brand */}
                    <div style={{flex: 1}}>
                        <div style={{fontWeight: 'bold', fontSize: '14px', lineHeight: '1.2'}}>
                            <Tooltip title={record.item_code || 'N/A'}>
                                {truncateText(record.item_code || 'N/A', 35)} ({record.brand_name || 'N/A'})
                            </Tooltip>
                        </div>
                        {/* Item Name */}
                        <div style={{color: 'gray', fontSize: '12px', lineHeight: '1.2', marginTop: '4px'}}>
                            <Tooltip title={record.item_name || 'N/A'}>
                                {truncateText(record.item_name || 'N/A', 35)}
                            </Tooltip>
                        </div>
                    </div>

                    {/* Serialized Item Icon */}
                    {record.has_serial_no && (
                        <Tooltip title="This is a serialized item">
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    backgroundColor: '#f0f5ff', // Light complementary background
                                    border: '1px solid #adc6ff', // Subtle border
                                    width: '24px',
                                    height: '24px',
                                    boxShadow: '0 1px 3px rgba(0, 0, 0, 0.1)', // Soft shadow
                                }}
                                onClick={() => handleSerialIconClick(record)}
                            >
                                <QrcodeOutlined
                                    style={{fontSize: '16px', color: '#597ef7'}} // Complementary icon color
                                />
                            </div>
                        </Tooltip>
                    )}
                </div>
            ),
        }
        , {title: 'Quantity', dataIndex: 'quantity', align: 'right'},
        {
            title: 'MRP',
            dataIndex: 'mrp',
            align: 'right',
            render: (value) => formatRupee(value),
        }, {
            title: 'Selling Rate',
            dataIndex: 'item_price',
            align: 'right',
            render: (value) => formatRupee(value),
        }, {
            title: 'Total Amount',
            align: 'right',
            render: (_, item) => formatRupee(item.item_price * item.quantity),
        },];

    const expandedRowRender = (record) => {
        const saleOrderItemId = record.id;
        const serialData = serialNumbers[saleOrderItemId] || [];
        const isLoading = serialLoading[saleOrderItemId];
        return (<div style={{padding: '8px'}}>
            {isLoading ? (<Spin/>) : serialData.length > 0 ? (serialData.map((item) => (
                <Tag key={item.serial_number_value} style={{marginBottom: '8px'}}>
                    {item.serial_number_value}
                </Tag>))) : (<Text type="secondary">No serial numbers assigned</Text>)}
        </div>);
    };


    return (<Drawer
        title={<div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
            <Title level={4} style={{margin: 0}}>Sales Order Details</Title>
            {orderDetails && (<OrderActionsForDetailsDrawer
                orderDetails={orderDetails}
                orderId={orderId}
                invoiceId={orderDetails.invoice}
                setOrderDetails={setOrderDetails}
                generatingInvoice={generatingInvoice}
                setGeneratingInvoice={setGeneratingInvoice}
                handleEditOrder={onEditOrder}
                refreshOrders={refreshOrders}
                setErrorMessage={setErrorMessage}
                setSuccessMessage={setSuccessMessage}
                setPaymentFormVisible={setPaymentFormVisible}
            />)}

        </div>}
        width={window.innerWidth > 1200 ? '70%' : '85%'}
        open={visible}
        onClose={onClose}
    >
        {loading || generatingInvoice ? (
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100%',
                textAlign: 'center'
            }}>
                <Spin size="large"/>
                <Text type="secondary" style={{marginTop: 16}}>
                    Processing your request...
                </Text>
            </div>
        ) : orderDetails ? (<>
            {/* Order Summary */}
            <Descriptions bordered size="small" column={4} style={{marginBottom: '20px'}}>
                {/* Order Number, Customer, and Salesperson */}
                <Descriptions.Item label="Order Number" span={2}>
                    {orderDetails.order_number}
                </Descriptions.Item>
                <Descriptions.Item label="Customer" span={2}>
                    {orderDetails.customer ? (
                        <>
                            {orderDetails.customer_name} ({orderDetails.mobile_number})
                        </>
                    ) : (
                        <Tooltip title="Customer is not registered in the system. Click to assign a customer.">
            <span style={{display: 'flex', alignItems: 'center'}}>
                <Badge status="warning" style={{marginRight: 8}}/>
                <span style={{flexGrow: 1}}>
                    {orderDetails.customer_name} ({orderDetails.mobile_number})
                </span>
                <Button
                    icon={<EditOutlined/>}
                    onClick={() => setCustomerModalVisible(true)}
                    style={{marginLeft: 12, padding: 0, fontSize: '16px', lineHeight: '22px'}}
                    size="small"
                    type="dashed"
                />
            </span>
                        </Tooltip>
                    )}
                </Descriptions.Item>


                <Descriptions.Item label="Order Date" span={2}>
                    {formatDate(orderDetails.order_date)}
                </Descriptions.Item>
                <Descriptions.Item label="Salesperson" span={2}>
                    {orderDetails.salesperson_name ? (
                        <>
                            {orderDetails.salesperson_name}
                            {orderDetails.salesperson_company_code && (
                                <span style={{color: 'gray'}}> ({orderDetails.salesperson_company_code})</span>
                            )}
                        </>
                    ) : (
                        'Not Assigned'
                    )}
                </Descriptions.Item>

                {/* Status and Approval Details */}
                <Descriptions.Item label="Status" span={2}>
                    <Badge
                        status={
                            isAutomaticallyApproved || isManuallyApproved
                                ? 'success'
                                : isRejected
                                    ? 'error'
                                    : 'warning'
                        }
                        style={{marginRight: 8}}
                    />
                    {orderDetails.status_display}
                </Descriptions.Item>

                <Descriptions.Item label="Approval Status" span={2}>
                    <Tooltip
                        title={
                            isAutomaticallyApproved
                                ? "This order was approved automatically"
                                : isRejected
                                    ? "The order was rejected by the approver"
                                    : needsApproval
                                        ? "This order needs approval"
                                        : "The order was approved manually by the approver"
                        }
                    >
                        <span>
                            <Badge
                                status={
                                    isAutomaticallyApproved || isManuallyApproved
                                        ? 'success'
                                        : isRejected
                                            ? 'error'
                                            : 'warning'
                                }
                                style={{marginRight: 8}}
                            />
                            {isAutomaticallyApproved
                                ? 'Automatically Approved'
                                : isRejected
                                    ? 'Approval Refused by Approver'
                                    : needsApproval
                                        ? 'Needs Approval'
                                        : 'Approval Authorized by Approver'}
                        </span>
                    </Tooltip>
                </Descriptions.Item>

                <Descriptions.Item label="Total Quantity" span={1}>
                    {orderDetails.total_quantity}
                </Descriptions.Item>
                <Descriptions.Item label="Order Value" span={1}>
                    {formatRupee(orderDetails.order_value)}
                </Descriptions.Item>
                <Descriptions.Item label="Total Discount" span={2}>
                    {orderDetails.total_discount_amount > 0 ? (<>
                        {formatRupee(orderDetails.total_discount_amount)} ({orderDetails.total_discount_percentage}%)
                    </>) : (<Text>No Discount</Text>)}
                </Descriptions.Item>

            </Descriptions>

            <Title level={5} style={{marginTop: 20}}>Items</Title>
            <Table
                columns={columns}
                dataSource={orderDetails.items}
                rowKey="id"
                pagination={false}
                size="small"
                expandable={{
                    expandedRowRender, onExpand: async (expanded, record) => {
                        if (expanded && !serialFetched[record.id]) {
                            await fetchSerialNumbers(record.id);
                        }
                    },
                }}
                locale={{emptyText: <Empty description="No products available"/>}}
                scroll={{x: 800}}
            />

            <Descriptions bordered size="small" column={4} style={{marginTop: 20}}>
                <Descriptions.Item label="Additional Discount" span={2}>
                    {formatRupee(orderDetails.additional_discount_amount || 0)}
                </Descriptions.Item>
                <Descriptions.Item label="Mode of Delivery" span={2}>
                    {orderDetails.mode_of_delivery_display || "N/A"}
                </Descriptions.Item>

                <Descriptions.Item label="Invoice Date" span={2}>
                    {formatDate(orderDetails.invoice_date || 0)}
                </Descriptions.Item>
                <Descriptions.Item label="Invoice Number" span={2}>
                    {orderDetails.invoice_number}
                </Descriptions.Item>
                <Descriptions.Item label="Invoice Amount" span={2}>
                    {formatRupee(orderDetails.invoice_amount || 0)}
                </Descriptions.Item>
                <Descriptions.Item label="Received Amount" span={2}>
                    {formatRupee(orderDetails.received_amount || 0)}
                    {orderDetails.draft_received_amount > 0 && (
                        <div style={{color: 'red'}}>
                            Draft: {formatRupee(orderDetails.draft_received_amount)}
                        </div>
                    )}
                </Descriptions.Item>

                <Descriptions.Item label="Invoice PDF" span={2}>
                    {orderDetails.invoice_pdf_url ? (
                        <a href={orderDetails.invoice_pdf_url} target="_blank" rel="noopener noreferrer">
                            View Invoice
                        </a>
                    ) : "N/A"}
                </Descriptions.Item>
                <Descriptions.Item label="Balance" span={2}>
                    {formatRupee(orderDetails.balance || 0)}
                </Descriptions.Item>
                <Descriptions.Item label="Billing Address" span={2}>
                    {orderDetails.billing_address_full}
                </Descriptions.Item>
                <Descriptions.Item label="Shipping Address" span={2}>
                    {orderDetails.shipping_address_full}
                </Descriptions.Item>

            </Descriptions>

            <Table
                title={() => <Title level={5}>Payments</Title>}
                columns={paymentColumns}
                dataSource={payments}
                rowKey="id"
                loading={loading}
                pagination={false}
                size="small"
                locale={{emptyText: <Empty description="No payments available"/>}}
                scroll={{x: 800}}
            />


            <Descriptions bordered size="small" column={window.innerWidth > 800 ? 2 : 1}
                          style={{marginTop: 20}}>
                <Descriptions.Item label="Company">
                    {orderDetails.company_name}
                </Descriptions.Item>
                <Descriptions.Item label="Branch">
                    {orderDetails.branch_name}
                </Descriptions.Item>
                <Descriptions.Item label="Created Date">
                    {formatDateTime(orderDetails.created_date)}
                </Descriptions.Item>
                <Descriptions.Item label="Created By">
                    {orderDetails.created_by_name}
                </Descriptions.Item>
                <Descriptions.Item label="Updated Date">
                    {formatDateTime(orderDetails.updated_date)}
                </Descriptions.Item>
                <Descriptions.Item label="Updated By">
                    {orderDetails.updated_by_name}
                </Descriptions.Item>
                <Descriptions.Item label="Approved Date">
                    {orderDetails.approved_date ? formatDateTime(orderDetails.approved_date) : '-'}
                </Descriptions.Item>
                <Descriptions.Item label="Approved By">
                    {orderDetails.approved_by_name || '-'}
                </Descriptions.Item>
            </Descriptions>
            {showSerialInput && selectedItem && (
                <div style={{marginTop: 20}}>
                    <SerialNumberInput
                        visible={showSerialInput}
                        onClose={() => setShowSerialInput(false)}
                        transactionType="Sales"
                        orderNumber={orderDetails.order_number}
                        transactionItemId={selectedItem.id}
                        itemCode={selectedItem.item_code}
                        itemId={selectedItem.item}
                        quantity={selectedItem.quantity}
                        quantityRequired={selectedItem.quantity}
                        branchId={orderDetails.branch_id}
                        companyId={orderDetails.company_id}
                        existingSerialNumbers={selectedSerialNumbers[selectedItem.id] || []} // Pass only relevant serial numbers
                        onSerialNumberChange={(serials) => {
                            console.log('Updated Serial Numbers:', serials);
                        }}
                    />
                </div>
            )}
            <SerialNumberInputModal
                visible={serialModalVisible}
                onClose={() => setSerialModalVisible(false)}
                onSubmit={handleSerialNumbersSubmit}
                itemId={currentItemId} // numeric ID for sale_order_item
                SrItemId={currentItemDetails?.SrItem}
                initialSerialNumbers={
                    serialNumbers[currentItemId]?.map(
                        (s) => s.serial_number_value
                    ) || []
                }
                orderNumber={orderDetails.order_number}
                itemCode={currentItemDetails?.itemCode}
                quantity={currentItemDetails?.quantity}
                branchId={orderDetails.branch_id}
                setSuccessMessage={setSuccessMessage}
                setErrorMessage={setErrorMessage}
            />
        </>) : (<Text>No data available</Text>)}
        <NewPaymentForm
            visible={paymentFormVisible}
            onCancel={() => setPaymentFormVisible(false)}
            onSuccess={async () => {
                // If the payment was recorded successfully:
                setPaymentFormVisible(false);
                await refreshOrderDetails();
                refreshOrders();  // Refresh any data as needed
            }}
            sourceOfInitiation="sale_order"
            orderId={orderId}
            // Safely accessing `customer` property:
            customerId={orderDetails && orderDetails.customer ? orderDetails.customer.id : null}
            invoiceId={null}       // If you do not have an invoice yet
            companyId={orderDetails ? orderDetails.company_id : null}
            branchId={orderDetails ? orderDetails.branch_id : null}
            setErrorMessage={setErrorMessage}
            setInfoMessage={(msg) => console.log(msg)} // or your own state if you want to show a message
            setSuccessMessage={setSuccessMessage}
            orderDetails={orderDetails}
            refreshOrders={refreshOrders}
        />
        <UpdateCustomerDetails
            visible={customerModalVisible}
            onCancel={() => setCustomerModalVisible(false)}
            onCustomerChange={handleCustomerChange}
            setSuccessMessage={(msg) => console.log(msg)} // Replace with actual message handling
            setErrorMessage={(msg) => console.error(msg)} // Replace with actual error handling
        />
    </Drawer>);
};

export default SaleOrderDetailsDrawer;
