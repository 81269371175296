import React from 'react';
import { Card, Tabs } from 'antd';
import SummaryDashboard from './SummaryDashboard';
import CampaignsDashboard from './CampaignsDashboard';
import ContentDashboard from './ContentDashboard';

const { Tab } = Tabs;

const MarketingDashboard = () => {
  // Define the tab items with their respective components
  const tabItems = [
    {
      label: "Campaigns",
      key: "1",
      children: <CampaignsDashboard />
    },
    {
      label: "Content",
      key: "3",
      children: <ContentDashboard />
    }
  ];

  return (
      <>
          <SummaryDashboard />
    <Card bordered={false}>
      <Tabs defaultActiveKey="1" items={tabItems} />
    </Card>
          </>
  );
};

export default MarketingDashboard;
