import {
    MailOutlined,
    AppstoreOutlined,
    SettingOutlined,
    ShoppingCartOutlined,
    MenuUnfoldOutlined, CommentOutlined
} from '@ant-design/icons';
import { hasAccess } from './uacUtils';

const menuDataRender = (userRole) => [
    {
        key: 'dashboard',
        name: 'Dashboard',
        path: '/sales-dashboard',
        icon: <AppstoreOutlined />,
        visible: hasAccess(userRole, 'sales-dashboard'),
    },
    {
        key: 'sales',
        name: 'Sales',
        icon: <ShoppingCartOutlined />,
        children: [
            { key: 'sales-dashboard', name: 'Dashboard', path: '/sales-dashboard', visible: hasAccess(userRole, 'sales-dashboard') },
            { key: '/sales', name: 'Sales', path: '/sales', visible: hasAccess(userRole, 'sales') },
            { key: 'sales-orders', name: 'Sales Orders', path: '/sales-orders', visible: hasAccess(userRole, 'sales_orders') },
            { key: 'invoices', name: 'Invoices', path: '/invoices', visible: hasAccess(userRole, 'invoices') },
            { key: 'customer-payments', name: 'Customer Payments', path: '/customer-payments', visible: hasAccess(userRole, 'customer-payments') },
            { key: 'register-customer', name: 'Register Customer', path: '/register-customer', visible: hasAccess(userRole, 'register_customer') },
            { key: 'product-catalogue', name: 'Product Catalog', path: '/product-catalogue', visible: hasAccess(userRole, 'product_catalogue') },
        ].filter(item => item.visible !== false),
    },
    {
        key: 'crm',
        name: 'CRM',
        icon: <CommentOutlined />,
        children: [
            { key: 'marketing-dashboard', name: 'Marketing Dashboard', path: '/marketing-dashboard', visible: hasAccess(userRole, 'marketing-dashboard') },
            { key: 'leads-dashboard', name: 'Leads Dashboard', path: '/leads-dashboard', visible: hasAccess(userRole, 'leads-dashboard') },
            { key: 'create-customer', name: 'Register Customer', path: '/create-customer', visible: hasAccess(userRole, 'register_customer') },
            {key: 'customers', name: 'Customer List', path: '/customers', visible: hasAccess(userRole, 'customers') },
            {key: 'leads,', name: 'Leads', path: '/leads', visible: hasAccess(userRole, 'leads') },
            {key: 'marketing-event-list', name: 'Marketing Events', path: '/marketing-event-list', visible: hasAccess(userRole, 'marketing-event-list') },
            {key: 'marketing-content-list', name: 'Marketing Content', path: '/marketing-content-list', visible: hasAccess(userRole, 'marketing-content-list') },
        ].filter(item => item.visible !== false),
    },
    {
        key: 'inventory',
        name: 'Inventory',
        icon: <MenuUnfoldOutlined />,
        children: [
            { key: 'inventory-page', name: 'Inventory Management', path: '/inventory', visible: hasAccess(userRole, 'inventory') },
            { key: 'stock-list', name: 'Stock List', path: '/stock-list', visible: hasAccess(userRole, 'stock-list') },
            { key: 'stock-value', name: 'Company Stock Value', path: '/stock-value', visible: hasAccess(userRole, 'stock-value') },
            { key: 'serial-numbers', name: 'Serial Numbers', path: '/serial-numbers', visible: hasAccess(userRole, 'serial_numbers') },
            {key: 'stock-transfers', name:'Stock Transfers', path: '/stock-transfers', visible: hasAccess(userRole, 'stock-transfers') },

        ].filter(item => item.visible !== false),
    },

    {
        key: 'procurement',
        name: 'Procurement',
        icon: <MenuUnfoldOutlined />,
        children: [
            { key: '/procurement', name: 'Purchase Orders', path: '/procurement', visible: hasAccess(userRole, 'procurement') },
            { key: '/procurement/purchase-requests/open', name: 'Open Requests', path: '/procurement/purchase-requests/open', visible: hasAccess(userRole, 'purchase_requests_open') },
            { key: '/procurement/purchase-requests/closed', name: 'Closed Requests', path: '/procurement/purchase-requests/closed', visible: hasAccess(userRole, 'purchase_requests_closed') },
        ].filter(item => item.visible !== false),
    },
    {
        key: 'cash-transactions',
        name: 'Cash Management',
        icon: <MenuUnfoldOutlined />,
        children: [
            { key: 'branch-cash-dashboard', name: 'Branch Cash ExpensesDashboard', path: '/branch-cash-dashboard', visible: hasAccess(userRole, 'branch-cash-dashboard') },
            { key: 'company-cash-dashboard', name: 'Company Cash ExpensesDashboard', path: '/company-cash-dashboard', visible: hasAccess(userRole, 'company-cash-dashboard') },
            { key: 'cash-transfers', name: 'Cash Transfers', path: '/cash-transfers', visible: hasAccess(userRole, 'cash-transfers') },
            { key: 'petty-cash', name: 'Petty Cash Requests', path: '/petty-cash', visible: hasAccess(userRole, 'petty-cash') },
        ].filter(item => item.visible !== false),
    },
    {
        key: 'expenses',
        name: 'Expenses',
        icon: <MenuUnfoldOutlined />,
        children: [
            { key: 'expense-dashboard', name: 'Dashboard', path: '/expense-dashboard', visible: hasAccess(userRole, 'expense-dashboard') },
            { key: 'expense-list', name: 'Expenses', path: '/expense-list', visible: hasAccess(userRole, 'expense-list') },
        ].filter(item => item.visible !== false),
    },
    {
        key: 'partners',
        name: 'Partners',
        icon: <MenuUnfoldOutlined />,
        children: [
            { key: 'partners-list', name: 'Partners List', path: '/partners', visible: hasAccess(userRole, 'partners') },
            { key: 'partner-branches', name: 'Partner Branches', path: '/partner-branches', visible: hasAccess(userRole, 'partners') },
            { key: 'payment-cycle-items', name: 'Payment Cycle Items', path: '/payment-cycle-items', visible: hasAccess(userRole, 'payment-cycle-items') },
        ].filter(item => item.visible !== false),
    },
    {
        key: 'settings',
        name: 'Settings',
        icon: <SettingOutlined />,
        children: [
            { key: 'states-list', name: 'States List', path: '/states_list', visible: hasAccess(userRole, 'states_list') },
            { key: 'countries-list', name: 'Countries List', path: '/countries_list', visible: hasAccess(userRole, 'countries_list') },
            { key: 'refresh-local-storage', name: 'Refresh Local Storage', path: '/refresh_local_storage', visible: hasAccess(userRole, 'refresh_local_storage') },
        ].filter(item => item.visible !== false),
    },
    {
        type: 'divider',
    },
    // {
    //     key: 'sales',
    //     name: 'Sales',
    //     icon: <ShoppingCartOutlined />,
    //     children: [
    //         { key: '/sales-orders', name: 'Sales Orders', path: '/sales-orders', visible: hasAccess(userRole, 'sales_orders') },
    //         { key: '/register-customer', name: 'Register Customer', path: '/register-customer', visible: hasAccess(userRole, 'register_customer') },
    //     ].filter(item => item.visible),
    // },
    {
        type: 'divider',
    },
].filter(item => item.children && item.children.length > 0);

export default menuDataRender;
