import React, {useEffect, useState} from 'react';
import {Button, Col, Form, Input, Row, Select} from 'antd';
import {useMessageContext} from "../../components/MessageContext";
import {createLeadApi} from "./crmApis";
import {useDispatch, useSelector} from "react-redux";
import {
    fetchCountriesWithStates,
    fetchCustomerSegments,
    fetchCustomerSources,
    fetchTerritories
} from "../../store/slices/locationSlice";
import {fetchCompanies} from "../../store/slices/companyBranchSlice"; // Adjust the path accordingly

const {Option} = Select;
const {TextArea} = Input;

const LeadRegistrationForm = ({onBack, refreshData}) => {
    const messageContext = useMessageContext();

    const dispatch = useDispatch();
    const {companies, loading} = useSelector((state) => state.companyBranch);

    const {countries, territories, customerSegments, customerSources} = useSelector((state) => state.configuration);

    const [selectedCompany, setSelectedCompany] = useState(null);
    const [selectedBranch, setSelectedBranch] = useState(null);
    const [branches, setBranches] = useState([]);


    useEffect(() => {
        if (countries.length === 0) dispatch(fetchCountriesWithStates());
        if (territories.length === 0) dispatch(fetchTerritories());
        if (customerSegments.length === 0) dispatch(fetchCustomerSegments());
        if (customerSources.length === 0) dispatch(fetchCustomerSources());


    }, [dispatch, countries, territories]);


    const [itemGroups, setItemGroups] = useState([]);

    // Fetch companies on component mount
    useEffect(() => {
        dispatch(fetchCompanies());
    }, [dispatch]);

    // Update branches when the company is changed
    useEffect(() => {
        const company = companies.find((comp) => comp.id === selectedCompany);
        setBranches(company?.branches || []);
    }, [selectedCompany, companies]);

    const handleCompanyChange = (companyId) => {
        setSelectedCompany(companyId);
        setSelectedBranch(null); // Reset branch selection
    };

    useEffect(() => {
        // For demo purposes, using static options


        setItemGroups([
            {id: 1, item_group_name: 'Group One'},
            {id: 2, item_group_name: 'Group Two'},
        ]);
    }, []);

    // Form submission handler
    const onFinish = async (values) => {
        try {
            const payload = {
                ...values,
                generated_from: 'imp', // Using the "imp" branch: IDs are provided directly
            };

            await createLeadApi(payload);
            messageContext.success('Lead created successfully!');
            refreshData();
            onBack();

            // Optionally, reset the form or navigate away here
        } catch (error) {
            console.error('Error creating lead:', error);
            messageContext.error('Failed to create lead.');
        }
    };

    return (
        <div style={{padding: '24px'}}>
            <Form layout="vertical" onFinish={onFinish}>
                <Row gutter={16}>
                    <Col xs={24} sm={12}>
                        <Form.Item
                            name="customer_name"
                            label="Customer Name"
                            rules={[{required: true, message: 'Please input customer name!'}]}
                        >
                            <Input placeholder="Enter customer name"/>
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12}>
                        <Form.Item
                            name="mobile_number"
                            label="Mobile Number"
                            rules={[{required: true, message: 'Please input mobile number!'}]}
                        >
                            <Input placeholder="Enter mobile number"/>
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={16}>
                    <Col xs={24} sm={12}>
                        <Form.Item
                            name="company"
                            label="Company"
                            rules={[{required: true, message: 'Please select a company!'}]}
                        >
                            <Select
                                value={selectedCompany}
                                onChange={handleCompanyChange}
                                placeholder="Select a company"
                                notFoundContent="No Company Available"
                            >
                                {companies.map(company => (
                                    <Option key={company.id} value={company.id}>
                                        {company.company_name}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12}>
                        <Form.Item
                            name="branch"
                            label="Branch"
                            rules={[{required: true, message: 'Please select a branch!'}]}
                        >
                            <Select
                                value={selectedBranch}
                                onChange={(value) => setSelectedBranch(value)}
                                placeholder="Select a branch"
                                disabled={!selectedCompany}
                            >
                                {branches.map(branch => (
                                    <Option key={branch.id} value={branch.id}>
                                        {branch.branch_name}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={16}>
                    <Col xs={24} sm={12}>
                        <Form.Item
                            name="source"
                            label="Source"
                            rules={[{required: true, message: 'Please select a source!'}]}
                        >
                            <Select placeholder="Select Source">
                                {customerSources.map((source) => (
                                    <Option key={source.id} value={source.id}>{source.source_name}</Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12}>
                        <Form.Item
                            name="interested_in"
                            label="Interested In"
                        >
                            <Select placeholder="Select Interested In">
                                {itemGroups.map(group => (
                                    <Option key={group.id} value={group.id}>
                                        {group.item_group_name}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={16}>
                    <Col xs={24} sm={12}>
                        <Form.Item
                            name="requirements"
                            label="Requirements"
                            rules={[{required: true, message: 'Please enter requirements!'}]}
                        >
                            <TextArea rows={4} placeholder="Enter requirements..."/>
                        </Form.Item>
                    </Col>

                    <Col xs={24} sm={12}>
                        <Form.Item
                            name="estimated_revenue"
                            label="Estimated Revenue"
                            rules={[{required: true, message: 'Please enter estimated revenue!'}]}
                        >
                            <Input type="number" prefix="₹" suffix=".00" allowClear placeholder="Enter amount" />

                        </Form.Item>
                    </Col>
                </Row>

                {/* Button Row aligned to the right */}
                <Row justify="end" gutter={16} style={{marginTop: 24}}>
                    <Col>
                        <Button onClick={onBack}>
                            Back to Leads List
                        </Button>
                    </Col>
                    <Col>
                        <Button type="primary" htmlType="submit">
                            Register Lead
                        </Button>
                    </Col>
                </Row>
            </Form>
        </div>
    );
};

export default LeadRegistrationForm;
