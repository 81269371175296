import React from 'react';
import {Table, Tag, Typography, Button, Empty} from 'antd';
import { EyeOutlined } from '@ant-design/icons';

const { Paragraph } = Typography;

const STATUS_TAG_COLORS = {
    active: 'green',
    inactive: 'volcano',
    pending: 'gold'
};

const CustomersTable = ({ data, loading, pagination, onPageChange }) => {
    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            // Example of adding a tooltip or additional formatter
            render: text => <span>{text}</span>,
        },
        {
            title: 'Mobile Number',
            dataIndex: 'mobile_number',
            key: 'mobile_number'
        },
        {
        title: 'Email',
        dataIndex: 'email',
        key: 'email',
        render: text => text === 'nan' ? '' : text
    },
        {
          title: 'Source',
          dataIndex: 'customer_source_name',
          key: 'customer_source_name',
            align: 'center',
        },
        {
            title: 'Type',
            dataIndex: 'customer_type',
            key: 'customer_type',
            align: 'center',
            render: type => <Tag color="blue">{type}</Tag>
        },
        {
            title: 'Territory',
            dataIndex: 'territory_name',
            key: 'territory_name',
            align: 'center',
        }
    ];

    const viewCustomerDetails = (customer) => {
        // Logic to handle viewing customer details
        console.log('View details for:', customer.name);
        // This could trigger a modal, redirect to a detail page, etc.
    };

    // Custom pagination summary
    const showPaginationTotal = (total, range) => {
        return `Showing ${range[0]}-${range[1]} of ${total} records`;
    };

    return (
        <Table
            columns={columns}
            dataSource={data}
            rowKey={record => record.id}
             pagination={{
                ...pagination,
                showTotal: showPaginationTotal,
                onChange: onPageChange,
            }}
            loading={loading}
            size="small"
            scroll={{x: 'max-content'}}
            locale={{
                emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No records available."/>
            }}
        />
    );
};

export default CustomersTable;
