import React, {useEffect, useState} from 'react';
import {BrowserRouter as Router, Routes, Route, Navigate} from 'react-router-dom';
import {useSelector} from 'react-redux';
import Login from './features/auth/Login';
import Dashboard from './pages/Dashboard';
import LayoutWrapper from "./layouts/LayoutWrapper";
import StatesList from "./features/masters/StatesList";
import CountriesList from "./features/masters/CountriesList";
import SalesOrderList from "./features/sales/SalesOrderList";
import ProductList from "./features/products/ProductList";
import TransactionForm from "./features/products/TransactionForm";
import SalesPage from "./pages/SalesPage";
import CustomerRegistration from "./features/customers/CustomerRegistration";
import SalesAnalyticsDashboard from "./pages/SalesAnalyticsDashboard";
import Procurement from "./pages/ProcurementPage";
import InventoryPage from "./pages/InventoryPage";
import ProductCatalog from "./features/products/ProductCatalog";
import RefreshLocalStorage from "./pages/refreshLocalStorage";
import CompanyList from "./features/partners/CompanyList";
import BranchList from "./features/partners/BranchList";
import StockTransfersList from "./features/stockTransfer/StockTransfersList";
import InventoryInboundList from "./features/inventory/InventoryInboundList";
import CycleItemsList from "./features/partber_payments/CycleItemsList";
import InvoicesList from "./features/invoices/InvoicesList";
import CustomerPaymentsList from "./features/customer_payments/CustomerPaymentsList";
import SerialNumbersList from "./features/inventory/SerialNumbersList";
import CashTransactionsList from "./features/cash_transfers/CashTransactionsList";
import PettyCashTransactionsList from "./features/cash_transfers/PettyCashTransactionsList";
import BranchCashDashboard from "./pages/BranchCashDashboard";
import CompanyCashDashboard from "./pages/CompanyCashDashboard";
import InventoryStockList from "./features/inventory/InventoryStockList";
import ExpenseList from "./features/expenses/ExpenseList";
import ExpensesDashboard from "./features/expenses/ExpensesDashboard";
import hasAccess from "./layouts/uacUtils";
import EventsList from "./features/crm/EventsList";
import {MessageProvider} from "./components/MessageContext";
import CustomerList from "./features/customers/CustomerList";
import LeadsList from "./features/crm/LeadsList";
import MarketingDashboard from "./features/crm/marketing/MarketingDashboard";
import LeadsDashboard from "./features/crm/leads_dashboard/LeadsDashboard";
import CampaignContentList from "./features/crm/CampaignContentList";


const App = () => {
    const token = useSelector((state) => state.auth.token);
     const user = JSON.parse(localStorage.getItem('user'));
    const userRole = user?.user_role;
    const [isAuthenticated, setIsAuthenticated] = useState(!!token);


    // Update `isAuthenticated` state when `token` changes
    useEffect(() => {
        setIsAuthenticated(!!token);
    }, [token]);

    // ProtectedRoute component to avoid navigation loops
    const ProtectedRoute = ({element, permission}) => {
        if (!isAuthenticated) {
            return <Navigate to="/" replace/>;
        }
        // Check if the user has the necessary permission for this route
        if (!hasAccess(userRole, permission)) {
            return <Navigate to="/dashboard" replace />; // Redirect or display an error message
        }
        return element;
    };


    return (
        <MessageProvider>
        <Router>
            <Routes>
                <Route path="/" element={isAuthenticated ? <Navigate to="/dashboard" /> : <Login />} />
                <Route element={<LayoutWrapper />}>
                    <Route path="/dashboard" element={<ProtectedRoute element={<Dashboard />} permission="dashboard" />} />
                    <Route path="/branch-cash-dashboard" element={<ProtectedRoute element={<BranchCashDashboard />} permission="branch-cash-dashboard" />} />
                    <Route path="/company-cash-dashboard" element={<ProtectedRoute element={<CompanyCashDashboard />} permission="company-cash-dashboard" />} />
                    <Route path="/sales-dashboard" element={<ProtectedRoute element={<SalesAnalyticsDashboard />} permission="sales-dashboard" />} />
                    <Route path="/sales" element={<ProtectedRoute element={<SalesPage />} permission="sales" />} />
                    <Route path="/sales-orders" element={<ProtectedRoute element={<SalesOrderList />} permission="sales_orders" />} />
                    <Route path="/customers" element={<ProtectedRoute element={<CustomerList />} permission="customers" /> }/>
                    <Route path="/leads" element={<ProtectedRoute element={<LeadsList />} permission="leads" /> } />
                    <Route path="/inventory" element={<ProtectedRoute element={<InventoryPage />} permission="inventory" />} />
                    <Route path="/stock-list" element={<ProtectedRoute element={<InventoryStockList />} permission="stock-list" />} />
                    <Route path="/product-catalogue" element={<ProtectedRoute element={<ProductCatalog />} permission="product_catalogue" />} />
                    <Route path="/states_list" element={<ProtectedRoute element={<StatesList />} permission="states_list" />} />
                    <Route path="/countries_list" element={<ProtectedRoute element={<CountriesList />} permission="countries_list" />} />
                    <Route path="/register-customer" element={<ProtectedRoute element={<CustomerRegistration />} permission="register_customer" />} />
                    <Route path="/create-customer" element={<ProtectedRoute element={<CustomerRegistration />} permission="register_customer" />} />
                    <Route path="/procurement" element={<ProtectedRoute element={<Procurement />} permission="procurement" />} />
                    <Route path="/partners" element={<ProtectedRoute element={<CompanyList />} permission="partners" />} />
                    <Route path="/partner-branches" element={<ProtectedRoute element={<BranchList />} permission="partner-branches" />} />
                    <Route path="/stock-transfers" element={<ProtectedRoute element={<StockTransfersList />} permission="stock-transfers" />} />
                    <Route path="/stock-value" element={<ProtectedRoute element={<InventoryInboundList />} permission="stock-value" />} />
                    <Route path="/payment-cycle-items" element={<ProtectedRoute element={<CycleItemsList />} permission="payment-cycle-items" />} />
                    <Route path="/invoices" element={<ProtectedRoute element={<InvoicesList />} permission="invoices" />} />
                    <Route path="/customer-payments" element={<ProtectedRoute element={<CustomerPaymentsList />} permission="customer-payments" />} />
                    <Route path="/serial-numbers" element={<ProtectedRoute element={<SerialNumbersList />} permission="serial_numbers" />} />
                    <Route path="/cash-transfers" element={<ProtectedRoute element={<CashTransactionsList />} permission="cash-transfers" />} />
                    <Route path="/petty-cash" element={<ProtectedRoute element={<PettyCashTransactionsList />} permission="petty-cash" />} />
                    <Route path="/expense-list" element={<ProtectedRoute element={<ExpenseList />} permission="expense-list" />} />
                    <Route path="/expense-dashboard" element={<ProtectedRoute element={<ExpensesDashboard />} permission="expense-dashboard" />} />
                    <Route path="/products" element={<ProtectedRoute element={<ProductList />} permission="product_catalogue" />} />
                    <Route path="/refresh_local_storage" element={<ProtectedRoute element={<RefreshLocalStorage />} permission="refresh_local_storage" />} />
                    <Route path="/transactions" element={<ProtectedRoute element={<TransactionForm />} permission="transactions" />} />
                    <Route path="/marketing-dashboard" element={<ProtectedRoute element={<MarketingDashboard />} permission="marketing-dashboard" />} />
                    <Route path="/leads-dashboard" element={<ProtectedRoute element={<LeadsDashboard />} permission="leads-dashboard" />} />
                    <Route path="/marketing-event-list" element={<ProtectedRoute element={<EventsList />} permission="marketing-event-list" /> }/>
                    <Route path="/marketing-content-list" element={<ProtectedRoute element={<CampaignContentList />} permission="marketing-content-list" /> } />
                    <Route path="*" element={<Navigate to="/dashboard" />} />
                </Route>
            </Routes>
        </Router>
        </MessageProvider>
    );

};

export default App;
