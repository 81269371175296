import React, { useState, useEffect } from 'react';
import { Table, Spin, Alert } from 'antd';
import { fetchOpenLeadsByStatus } from './leadsDashboardApi';  // API function import

const OpenLeadsByStatus = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // Status choices mapping
  const statusDisplay = {
    'new': 'New',
    'contacted': 'Contacted',
    'qualified': 'Qualified',
    'proposal': 'Proposal Sent',
    'converted': 'Converted',
    'lost': 'Lost'
  };

    const sortOrder = [
    'New',
    'Contacted',
    'Qualified',
    'Proposal Sent',
    'Converted',
    'Lost'
  ];

  useEffect(() => {
    setLoading(true);
    fetchOpenLeadsByStatus()
      .then(response => {
        // Map the status codes to human-readable form
        const mappedData = response.data.map(item => ({
          ...item,
          status: statusDisplay[item.status] || item.status
        }));
        setData(mappedData);
        setLoading(false);
      })
      .catch(err => {
        setError(`Failed to fetch data: ${err.message || 'Unknown error'}`);
        setLoading(false);
      });
  }, []);
const columns = [
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      sorter: (a, b) => sortOrder.indexOf(a.status) - sortOrder.indexOf(b.status),
      sortDirections: ['ascend'],  // Only allow ascending sort
    },
    {
      title: 'Count',
      dataIndex: 'count',
      key: 'count'
    }
  ];

  if (loading) return <Spin tip="Loading..." />;
  if (error) return <Alert message="Error" description={error} type="error" showIcon />;

  return (
    <Table
      columns={columns}
      dataSource={data}
      rowKey="status"
      pagination={false}  // Remove pagination
      size="small"  // Smaller table size
    />
  );
};

export default OpenLeadsByStatus;
