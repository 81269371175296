import axios from 'axios';
import config from '../../config';
import {getAuthToken} from "../../utils/authUtils"; // Ensure you have a config file with your API's URL

const token = getAuthToken();

// Fetches the list of invoices with optional pagination and search parameters
export const fetchInvoicesApi = async (page = 1, search = '') => {
    try {
        const response = await axios.get(`${config.inxOmsApiUrl}/invoices/api/invoices/`, {
            headers: { Authorization: `Token ${token}` },
            params: { page, search },
        });
        return response.data;
    } catch (error) {
        throw new Error('Failed to load invoices: ' + error.message);
    }
};

// Fetches details for a specific invoice by ID
export const fetchInvoiceDetailsApi = async (invoiceId) => {
    try {
        const response = await axios.get(`${config.inxOmsApiUrl}/invoices/api/invoices/${invoiceId}/`, {
            headers: { Authorization: `Token ${token}` }
        });
        return response.data;
    } catch (error) {
        throw new Error(`Failed to fetch invoice details: ${error.message}`);
    }
};


// invoiceApi.js

export const generateInvoicePdfApi = async (invoiceId) => {
    try {
        const response = await axios.post(`${config.inxOmsApiUrl}/invoices/api/invoices/${invoiceId}/generate-invoice/`, {}, {
            headers: { Authorization: `Token ${token}` }
        });
        console.log(response);
        return response.data;  // Assuming the API returns the generated invoice or confirmation
    } catch (error) {
        console.error('Error generating invoice:', error);
        throw new Error(error.error);
    }
};

