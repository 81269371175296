// src/apis/leadsDashboardApi.js
import axios from 'axios';
import { getAuthToken } from "../../../utils/authUtils";
import config from "../../../config";

export const fetchLeadsSummary = async () => {
  const token = getAuthToken();
  try {
    const response = await axios.get(`${config.inxOmsApiUrl}/crm/api/leads-dash/summary/`, {
      headers: { Authorization: `Token ${token}` }
    });
    console.log("leads_summary: ", response);
    return response;  // Return the data part of the response
  } catch (error) {
    console.error("API Error: ", error);
    throw error;
  }
};

export const fetchOpenLeadsByStatus = async () => {
  const token = getAuthToken();
  try {
    const response = await axios.get(`${config.inxOmsApiUrl}/crm/api/leads-dash/open_by_status/`, {
      headers: { Authorization: `Token ${token}` }
    });
    console.log("open_leads_by_status: ", response);
    return response;
  } catch (error) {
    console.error("API Error: ", error);
    throw error;
  }
};

export const fetchSourceWiseConversion = async () => {
  const token = getAuthToken();
  try {
    const response = await axios.get(`${config.inxOmsApiUrl}/crm/api/leads-dash/source_wise_conversion/`, {
      headers: { Authorization: `Token ${token}` }
    });
    console.log("source_wise_conversion: ", response);
    return response;
  } catch (error) {
    console.error("API Error: ", error);
    throw error;
  }
};

export const fetchBranchWisePerformance = async () => {
  const token = getAuthToken();
  try {
    const response = await axios.get(`${config.inxOmsApiUrl}/crm/api/leads-dash/branch_performance/`, {
      headers: { Authorization: `Token ${token}` }
    });
    console.log("branch_wise_performance: ", response);
    return response;
  } catch (error) {
    console.error("API Error: ", error);
    throw error;
  }
};

export const fetchAssigneeWisePerformance = async () => {
  const token = getAuthToken();
  try {
    const response = await axios.get(`${config.inxOmsApiUrl}/crm/api/leads-dash/assignee_performance/`, {
      headers: { Authorization: `Token ${token}` }
    });
    console.log("assignee_wise_performance: ", response);
    return response;
  } catch (error) {
    console.error("API Error: ", error);
    throw error;
  }
};
