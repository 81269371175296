import React, {useEffect, useState} from 'react';
import {Button, Col, DatePicker, Empty, Form, Input, InputNumber, Modal, Row, Select} from 'antd';
import {createExpense} from './expensesAPI';
import {fetchExpenseAccountsApi} from '../common/commonApis';

const {Option} = Select;
const {TextArea} = Input;

const ExpenseForm = ({
                         companies,
                         branches,
                         companyId,
                         branchId,
                         userRole,
                         refreshData,
                         onClose,
                         setSuccessMessage,
                         setErrorMessage,
                         setTempSelectedCompany,
                     }) => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [file, setFile] = useState(null);
    const [expenseAccounts, setExpenseAccounts] = useState([]);
    const [filteredAccounts, setFilteredAccounts] = useState([]);
    const [filteredBranches, setFilteredBranches] = useState(branches);

    // Fetch accounts when company changes
    useEffect(() => {
        if (companyId) {
            fetchExpenseAccounts(companyId);
            setFilteredBranches(
                companies.find((company) => company.id === companyId)?.branches || []
            );
        }
        form.setFieldsValue({company: companyId, branch: branchId, modeOfPayout: 'petty_cash'});
    }, [companyId, branchId, companies]);


    const fetchExpenseAccounts = async (companyId) => {
        setLoading(true);
        try {
            const accounts = await fetchExpenseAccountsApi(companyId);
            setExpenseAccounts(accounts);
            setFilteredAccounts(accounts);
        } catch (error) {
            setErrorMessage('Failed to fetch accounts');
        } finally {
            setLoading(false);
        }
    };

    const handleCompanyChange = (selectedCompanyId) => {
        setTempSelectedCompany(selectedCompanyId);
        const selectedCompany = companies.find((company) => company.id === selectedCompanyId);
        setFilteredBranches(selectedCompany?.branches || []);
        form.setFieldsValue({branch: null}); // Reset branch selection when company changes
        fetchExpenseAccounts(selectedCompanyId);
    };

    const handleFileChange = (e) => {
        const files = e.target.files;
        if (files && files.length > 0) {
            setFile(files[0]);
        } else {
            setFile(null);
        }
    };

    const handleSearch = (value) => {
        if (!value) {
            setFilteredAccounts(expenseAccounts);
        } else {
            setFilteredAccounts(
                expenseAccounts.filter((account) =>
                    account.account_name.toLowerCase().includes(value.toLowerCase())
                )
            );
        }
    };

    const onSubmit = async (values) => {
        if (!file) {
            setErrorMessage('Please attach a file.');
            return;
        }

        const formData = new FormData();
        formData.append('company', values.company);
        formData.append('branch', values.branch);
        formData.append('mode_of_payout', values.modeOfPayout);
        formData.append('date', values.date.format('YYYY-MM-DD'));
        formData.append('sub_category', values.account);
        formData.append('submitted_amount', values.amount);
        formData.append('description', values.description);
        formData.append('attachment', file);

        try {
            const response = await createExpense(formData); // Call the API
            console.log('Expense creation successful:', response);
            setSuccessMessage('Expense submitted successfully!');
            refreshData(); // Refresh data after successful submission
            form.resetFields(); // Reset the form fields
            setFile(null); // Clear the file
            onClose();
        } catch (error) {
            console.error('Error submitting expense:', error);
            setErrorMessage(
                error.response?.data?.detail || 'An error occurred while submitting the expense.'
            );
        }
    };

    return (
        <Modal
            title="Add Expense"
            open={true} // Always visible since it is the main form
            onCancel={() => {
                form.resetFields();
                setFile(null);
                if (onClose) onClose();
            }}
            width={"80%"}
            footer={[
                <Button key="cancel" onClick={onClose}>
                    Cancel
                </Button>,
                <Button key="submit" type="primary" onClick={() => form.submit()}>
                    Submit
                </Button>,
            ]}
        >
            <Form form={form} layout="vertical" onFinish={onSubmit}>
                {/* Row 1 */}
                <Row gutter={24}>
                    <Col span={12}>
                        <Form.Item
                            name="company"
                            label="Company"
                            rules={[{required: true, message: 'Please select a company'}]}
                        >
                            <Select
                                placeholder="Select company"
                                onChange={handleCompanyChange}
                                disabled={!['super_admin', 'admin'].includes(userRole)}
                            >
                                {companies.map((company) => (
                                    <Option key={company.id} value={company.id}>
                                        {company.company_name}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="branch"
                            label="Branch"
                            rules={[{required: true, message: 'Please select a branch'}]}
                        >
                            <Select placeholder="Select branch">
                                {filteredBranches.map((branch) => (
                                    <Option key={branch.id} value={branch.id}>
                                        {branch.branch_name}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item
                            name="modeOfPayout"
                            label="Mode of Payout"
                            rules={[{required: true, message: 'Please select a mode of payout'}]}
                        >
                            <Select>
                                <Option value="petty_cash">Petty Cash</Option>
                                <Option value="head_office">Head Office</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="requestedBy"
                            label="Requested By"
                            // rules={[{ required: true, message: 'Please select a requester' }]}
                        >
                            <Select placeholder="Select requester">
                                {/* Placeholder values, replace with actual data */}
                                <Option value="1">Requester 1</Option>
                                <Option value="2">Requester 2</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>

                {/* Row 2 */}
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item
                            name="date"
                            label="Date"
                            rules={[{required: true, message: 'Please select a date'}]}
                        >
                            <DatePicker style={{width: '100%'}} format="YYYY-MM-DD"/>
                        </Form.Item>
                        <Form.Item
                            name="account"
                            label="Account"
                            rules={[{required: true, message: 'Please select an account'}]}
                        >
                            <Select
                                showSearch
                                allowClear
                                placeholder="Search account"
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().includes(input.toLowerCase())
                                }
                                notFoundContent={
                                    <Empty
                                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                                        description="No accounts found."
                                    />
                                }
                                style={{width: '100%'}}
                            >
                                {filteredAccounts.map((account) => (
                                    <Option key={account.id} value={account.id}>
                                        {account.account_name}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>


                        <Form.Item
                            name="amount"
                            label="Amount"
                            rules={[{required: true, message: 'Please enter an amount'}]}
                        >
                            <InputNumber min={0} style={{width: '100%'}} prefix="Rs." suffix="INR" />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="attachment"
                            label="Attachment"
                            rules={[{required: true, message: 'Please attach a file'}]}
                        >
                            <Input type="file" onChange={handleFileChange}/>
                        </Form.Item>
                        <Form.Item name="description" label="Description">
                            <TextArea rows={4} placeholder="Enter description"/>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
};

export default ExpenseForm;
