import React, {useEffect, useState} from 'react';
import {Button, Col, Input, message, Modal, Row, Select} from 'antd';
import ExpenseTable from './ExpenseTable';
import {getExpenses} from './expensesAPI';
import ExpenseForm from "./ExpenseForm";
import {useDispatch, useSelector} from "react-redux";
import {fetchCompanies} from "../../store/slices/companyBranchSlice";
import moment from "moment";
import {fetchExpenseSubTypes} from "../../store/slices/locationSlice";
import {DeleteOutlined, FilterOutlined, PlusOutlined} from "@ant-design/icons";

const {Search} = Input;
const {Option} = Select;

const STATUS_CHOICES = [
    {value: 'draft', label: 'Draft'},
    {value: 'reported', label: 'Reported'},
    {value: 'processed', label: 'Processed'},
    {value: 'closed', label: 'Closed'},
    {value: 'canceled', label: 'Canceled'},
];

const MODE_OF_PAYOUT_CHOICES = [
    {value: 'petty_cash', label: 'Petty Cash'},
    {value: 'head_office', label: 'Head Office'}
];


const ExpenseList = () => {
    const dispatch = useDispatch();
    const [expenses, setExpenses] = useState([]);
    const [loading, setLoading] = useState(false);
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 10,
        total: 0
    });

    const [successMessage, setSuccessMessage] = useState(null);
    const [infoMessage, setInfoMessage] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);
    const [warningMessage, setWarningMessage] = useState(null);
    const [messageApi, contextHolder] = message.useMessage();

    const {companies, loading: companiesLoading} = useSelector((state) => state.companyBranch);
    const [branches, setBranches] = useState([]);
    const [filteredBranches, setFilteredBranches] = useState([]);
    const [tempSelectedCompany, setTempSelectedCompany] = useState('');
    const [isNewExpenseModalVisible, setIsNewExpenseModalVisible] = useState(false);

    const {expenseSubTypes} = useSelector((state) => state.configuration);

    // Satus filters

    const [selectedStatus, setSelectedStatus] = useState('');
    const [selectedModeOfPayout, setSelectedModeOfPayout] = useState('');
    const [selectedCategory, setSelectedCategory] = useState('');
    const [selectedCompany, setSelectedCompany] = useState('');
    const [selectedBranch, setSelectedBranch] = useState('');


    const user = JSON.parse(localStorage.getItem('user'));
    const userRole = user?.user_role;

    const companyId = user?.company_id || '';
    const companyName = user?.company || '';
    const companyCode = user?.company_code
    const branchId = user?.branch_id || '';
    const branchName = user?.branch || '';
    const branchCode = user?.branch_code || '';

    const refreshData = () => {
        fetchExpenses(pagination.current, pagination.pageSize);
    };

    useEffect(() => {
        if (expenseSubTypes.length === 0) dispatch(fetchExpenseSubTypes());


    }, [dispatch, expenseSubTypes]);

    // Fetch companies on component mount
    useEffect(() => {
        if (!companies || companies.length === 0) {
            dispatch(fetchCompanies());
        }
    }, [dispatch, companies]);

    // Update branches when a company is selected
    useEffect(() => {
        const company = companies.find((comp) => comp.id === tempSelectedCompany);
        setBranches(company ? company.branches : []);
    }, [tempSelectedCompany, companies]);

    // Update useEffect to handle empty strings as trigger for placeholders
    useEffect(() => {
        if (selectedCompany === '') {
            setSelectedBranch('');
            setFilteredBranches([]);
        } else {
            const company = companies.find(comp => comp.id === selectedCompany);
            setFilteredBranches(company ? company.branches : []);
        }
    }, [selectedCompany, companies]);


    // Handle displaying success and error messages using useEffect
    useEffect(() => {
        if (successMessage) {
            messageApi.open({
                type: 'success',
                content: successMessage,
                duration: 2.5,
            });
            setSuccessMessage(null);
        }
        if (errorMessage) {
            messageApi.open({
                type: 'error',
                content: errorMessage,
                duration: 2.5,
            });
            setErrorMessage(null);
        }
        if (infoMessage) {
            messageApi.open({
                type: 'info',
                content: infoMessage,
                duration: 2.5,
            });
            setInfoMessage(null);
        }
        if (infoMessage) {
            messageApi.warning({
                type: 'warning',
                content: warningMessage,
                duration: 2.5,
            });
            setWarningMessage(null);
        }
    }, [successMessage, errorMessage, infoMessage, warningMessage, messageApi]);

    useEffect(() => {
        fetchExpenses(pagination.current, pagination.pageSize);
    }, []);

    const handleTableChange = (pagination) => {
        fetchExpenses(pagination.current, pagination.pageSize);
    };

    const fetchExpenses = async (page, pageSize) => {
        setLoading(true);

        const params = {
            page,
            pageSize,
            status: selectedStatus,
            modeOfPayout: selectedModeOfPayout,
            category: selectedCategory,
            company: selectedCompany,
            branch: selectedBranch,
        };
        try {
            const data = await getExpenses(params);
            setExpenses(data.results);  // Assuming the response format includes 'results' and 'count'
            setPagination({
                ...pagination,
                total: data.count, // Total count of records
                current: page,
                pageSize: pageSize
            });
            setLoading(false);
        } catch (error) {
            setErrorMessage('Failed to fetch expenses');
            setLoading(false);
        }
    };



    const applyFilters = () => {
        console.log("Applying filters...");
        fetchExpenses(1, pagination.pageSize);
    };

    const clearFilters = () => {
    console.log("Clearing filters...");

    new Promise(resolve => {
        // Set all filter states
        setSelectedStatus('');
        setSelectedModeOfPayout('');
        setSelectedCategory('');
        setSelectedCompany('');
        setSelectedBranch('');

        // Update pagination if necessary
        setPagination(prev => {
            if (prev.current !== 1) {
                return { ...prev, current: 1 };
            } else {
                resolve();  // Resolve the promise if no pagination change is needed
                return prev;
            }
        });

        // This timeout is to ensure that the state updates above have been processed
        setTimeout(resolve, 0);
    }).then(() => {
        // Fetch data after all state updates
        fetchExpenses(1, pagination.pageSize);
    });
};



    const showNewExpenseForm = () => {
        setIsNewExpenseModalVisible(true);
    };

    const handleNewExpenseFormCancel = () => {
        setIsNewExpenseModalVisible(false);
    };

    const onView = (record) => {
        Modal.info({
            title: 'Expense Details',
            content: (
                <div>
                    <p><strong>Date:</strong> {moment(record.date).format('YYYY-MM-DD')}</p>
                    <p><strong>Category:</strong> {record.category_name}</p>
                    <p><strong>Sub-Category:</strong> {record.sub_category_name}</p>
                    <p><strong>Amount:</strong> ₹{parseFloat(record.submitted_amount).toFixed(2)}</p>
                    <p><strong>Status:</strong> {record.status_display}</p>
                    <p><strong>Description:</strong> {record.description}</p>
                </div>
            ),
            onOk() {
            },
        });
    };

    return (
        <div>
            {contextHolder}
            {!isNewExpenseModalVisible &&
                <>          <Row gutter={16}>
                    <Col span={16}>
                        <Row gutter={16}>
                            <Col span={8}>
                                <Select
                                    placeholder="Filter by Status"
                                    onChange={setSelectedStatus}
                                    value={selectedStatus || undefined}
                                    allowClear
                                    style={{width: '100%'}}
                                >
                                    {STATUS_CHOICES.map((status) => (
                                        <Option key={status.value} value={status.value}>{status.label}</Option>
                                    ))}
                                </Select>
                            </Col>
                            <Col span={8}>
                                <Select
                                    placeholder="Mode of Payout"
                                    onChange={setSelectedModeOfPayout}
                                    value={selectedModeOfPayout || undefined}
                                    allowClear
                                    style={{width: '100%'}}
                                >
                                    {MODE_OF_PAYOUT_CHOICES.map((mode) => (
                                        <Option key={mode.value} value={mode.value}>{mode.label}</Option>
                                    ))}
                                </Select>
                            </Col>
                            <Col span={8}>
                                <Select
                                    placeholder="Select Category"
                                    onChange={setSelectedCategory}
                                    value={selectedCategory || undefined}
                                    allowClear
                                    style={{width: '100%'}}
                                >
                                    {expenseSubTypes.map((subtype) => (
                                        <Option key={subtype.sub_type_id}
                                                value={subtype.sub_type_id}>{subtype.sub_type_name}</Option>
                                    ))}
                                </Select>
                            </Col>
                        </Row>
                        <Row gutter={16} style={{marginTop: '10px'}}>
                            <Col span={8}>
                                <Select
                                    placeholder="Select Company"
                                    onChange={setSelectedCompany}
                                    value={selectedCompany || undefined}
                                    allowClear
                                    style={{width: '100%'}}
                                >
                                    {companies.map((company) => (
                                        <Option key={company.id} value={company.id}>{company.company_name}</Option>
                                    ))}
                                </Select>
                            </Col>
                            <Col span={8}>
                                <Select
                                    placeholder="Select Branch"
                                    onChange={setSelectedBranch}
                                    value={selectedBranch || undefined}
                                    disabled={!selectedCompany}
                                    allowClear
                                    style={{width: '100%'}}
                                >
                                    {filteredBranches.map((branch) => (
                                        <Option key={branch.id} value={branch.id}>{branch.branch_name}</Option>
                                    ))}
                                </Select>
                            </Col>
                        </Row>
                    </Col>
                    <Col span={8}>
                        <Button
                            type="primary"
                            onClick={applyFilters}
                            style={{width: '49%', marginRight: '1%'}}
                            icon={<FilterOutlined/>}  // Ant Design icon for filtering
                        >
                            Apply Filters
                        </Button>
                        <Button
                            type="primary"
                            onClick={clearFilters}
                            style={{width: '49%', backgroundColor: '#c41a28', borderColor: '#c41a28'}}
                            icon={<DeleteOutlined/>}  // Ant Design icon for deletions or clear actions
                        >
                            Clear Filters
                        </Button>
                        <Button
                            type="primary"
                            onClick={() => showNewExpenseForm()}
                            style={{
                                width: '100%',
                                marginTop: '10px',
                                backgroundColor: '#52c41a',
                                borderColor: '#52c41a'
                            }} // Green color for add actions
                            icon={<PlusOutlined/>}  // Ant Design icon for adding new items
                        >
                            Add Expense
                        </Button>
                    </Col>

                </Row>


                    <ExpenseTable
                        expenses={expenses}
                        onView={onView}
                        loading={loading}
                        pagination={pagination}
                        onChange={handleTableChange}
                        showNewExpenseForm={showNewExpenseForm}
                        setErrorMessage={setErrorMessage}
                        setSuccessMessage={setSuccessMessage}
                        setInfoMessage={setInfoMessage}
                        setWarningMessage={setWarningMessage}
                        refreshData={refreshData}
                        userRole={userRole}
                    />
                </>
            }
            {isNewExpenseModalVisible &&
                <ExpenseForm
                    setErrorMessage={setErrorMessage}
                    setSuccessMessage={setSuccessMessage}
                    onClose={handleNewExpenseFormCancel}
                    setTempSelectedCompany={setTempSelectedCompany}
                    refreshData={fetchExpenses}
                    companies={companies}
                    branches={branches}
                    companyId={companyId}
                    branchId={branchId}
                    userRole={userRole}

                />
            }


        </div>
    );
};

export default ExpenseList;
