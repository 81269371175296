import React, {useEffect, useState} from 'react';
import {Button, Descriptions, Drawer, Space, Tag, Timeline, Typography} from 'antd';
import {fetchLeadDetails, fetchLeadInteractions} from './crmApis';
import {formatDate, formatDateTime} from "../../utils/dateUtils";
import {CloseOutlined} from "@ant-design/icons";
import LeadEditModal from "./LeadEditModal";
import UpdateAssigneeModal from "./UpdateAssigneeModal";
import {useMessageContext} from "../../components/MessageContext";
import {formatRupee} from "../../utils/utils";

const {Text} = Typography;


const LeadDetailsDrawer = ({
                               visible,
                               leadId,
                               onClose,
                               onEdit,
                               onUpdateFollowUp,
                               refreshInteractions,
                               refreshData,
                               setSelectedLead
                           }) => {
    const messageContext = useMessageContext();
    const [lead, setLead] = useState(null);
    const [interactions, setInteractions] = useState([]);
    const [loadingInteractions, setLoadingInteractions] = useState(false);
    const [loading, setLoading] = useState(false);

    const [currentPage, setCurrentPage] = useState(1);
    const [hasMoreInteractions, setHasMoreInteractions] = useState(false);
    const [assigneeVisible, setAssigneeVisible] = useState(false);
    const [editModalVisible, setEditModalVisible] = useState(false);

    const handleUpdateFollowUpClick = () => {
        setSelectedLead(lead);
        onUpdateFollowUp();
    }

    // Function to fetch latest lead details
    const fetchLeadData = async () => {
        if (!leadId) return;
        setLoading(true);
        try {
            const leadData = await fetchLeadDetails(leadId);
            setLead(leadData);
        } catch (error) {
            console.error("Failed to fetch lead details:", error);
        } finally {
            setLoading(false);
        }
    };

    // Fetch lead details when drawer opens
    useEffect(() => {
        if (visible && leadId) {
            fetchLeadData();
        }
    }, [visible, leadId, refreshInteractions]);

    useEffect(() => {
        if (visible && lead && lead.id) {
            setCurrentPage(1);
            setInteractions([]);
            setHasMoreInteractions(false);
            fetchMoreInteractions(1);  // This will set page to 1 internally
        }
    }, [visible, lead, refreshInteractions]);

    const fetchMoreInteractions = async (page = currentPage) => {
        setLoadingInteractions(true);
        try {
            const data = await fetchLeadInteractions(lead.id, {page, pageSize: 10});
            setInteractions(prev => page === 1 ? data.results : [...prev, ...data.results]);
            setHasMoreInteractions(data.next !== null);
            setCurrentPage(page + 1);
        } catch (error) {
            console.error("Error fetching more interactions", error);
        } finally {
            setLoadingInteractions(false);
        }
    };

    const handleEditLead = () => {
  setSelectedLead(lead);
  setEditModalVisible(true);
};


    const timelineItems = interactions.map(interaction => ({
        label: "",
        children: (
            <div>
                <div style={{display: 'flex', alignItems: 'center', gap: '10px', flexWrap: 'wrap'}}>
                    <Text type="secondary">
                        {formatDateTime(interaction.interaction_date)}
                    </Text>
                    <Text>
                        <strong>{interaction.interaction_type_display}</strong> by <strong>{interaction.interacted_by_name}</strong>
                    </Text>
                    <Tag color={interaction.was_on_time ? "green" : "red"}>
                        {interaction.was_on_time ? "On Time" : "Delayed"}
                    </Tag>
                </div>

                <div>
                    {interaction.details}
                </div>
            </div>
        ),
        key: interaction.id
    }));


    return (
        <>
            <Drawer
                title={
                    <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                        <span>Lead Details</span>
                        <Space>
                            {lead && (lead.status !== 'converted' && lead.status !== 'lost') && (
                                <>

                                    <Button onClick={handleUpdateFollowUpClick} type="default">Update Follow Up</Button>
                                    <Button onClick={handleEditLead} type="primary">Edit Lead</Button>
                                    <Button onClick={() => setAssigneeVisible(true)}>Update Assignee</Button>

                                </>
                            )}
                            <Button onClick={onClose} icon={<CloseOutlined/>}>Close</Button>
                        </Space>

                    </div>
                }
                placement="right"
                closable={true}
                onClose={onClose}
                open={visible}
                width="75%"
            >
                {lead ? (
                    <>
                        <Descriptions column={2} bordered size="small" layout="horizontal" style={{marginBottom: 16}}>
                            <Descriptions.Item label="ID">{lead.id}</Descriptions.Item>
                            <Descriptions.Item label="Customer Name">{lead.customer_name}</Descriptions.Item>
                            <Descriptions.Item label="Phone Number">{lead.mobile_number}</Descriptions.Item>
                            <Descriptions.Item label="Email">{lead.email}</Descriptions.Item>
                            <Descriptions.Item label="Status">{lead.status_display}</Descriptions.Item>
                            <Descriptions.Item
                                label="Conversion Status">{lead.conversion_status ? 'Converted' : 'Not Converted'}</Descriptions.Item>
                            <Descriptions.Item
                                label="Conversion Date">{lead.conversion_date ? formatDate(lead.conversion_date) : '—'}</Descriptions.Item>
                            <Descriptions.Item
                                label="Closed Date">{lead.closed_date ? formatDateTime(lead.closed_date) : '—'}</Descriptions.Item>
                            <Descriptions.Item
                                label="Follow-up Date">{lead.follow_up_date ? formatDateTime(lead.follow_up_date) : '—'}</Descriptions.Item>
                            <Descriptions.Item label="Assignee">{lead.assigned_to_name}</Descriptions.Item>
                            <Descriptions.Item label="Requirements">{lead.requirements || '—'}</Descriptions.Item>
                            <Descriptions.Item label="Estimated Revenue">{formatRupee(lead.estimated_revenue)}</Descriptions.Item>
                            <Descriptions.Item label="Lost Reason">{lead.lost_reason_text || '—'}</Descriptions.Item>

                            <Descriptions.Item label="Company">{lead.company_name || '—'}</Descriptions.Item>
                            <Descriptions.Item label="Branch">{lead.branch_name || '—'}</Descriptions.Item>
                        </Descriptions>

                        {/* Timeline for Interactions using the new `items` prop */}
                        <h3 style={{marginBottom: '16px'}}>Interactions</h3>
                        <Timeline items={timelineItems} mode="left"/>
                        {hasMoreInteractions && (
                            <Button onClick={() => fetchMoreInteractions(currentPage)} loading={loadingInteractions}
                                    style={{marginTop: 16, marginBottom: 16}}>
                                Load More
                            </Button>
                        )}
                    </>
                ) : (
                    <p>No lead details available.</p>
                )}

            </Drawer>
            {/* Modals */}
            <LeadEditModal
                visible={editModalVisible}
                lead={lead}
                onClose={() => setEditModalVisible(false)}
                onSuccess={() => {
                    refreshData(); // Make sure this fetches lead details correctly
                    fetchLeadData(); // This ensures you fetch the updated details for the current lead
                }}/>
            <UpdateAssigneeModal
                visible={assigneeVisible}
                lead={lead}
                onClose={() => setAssigneeVisible(false)}
                onSuccess={() => {
                    refreshData(); // Make sure this fetches lead details correctly
                    fetchLeadData(); // This ensures you fetch the updated details for the current lead
                }}
            />

        </>
    );
};

export default LeadDetailsDrawer;
