// src/apis/dashboardApi.js
import axios from 'axios';
import {getAuthToken} from "../../../utils/authUtils";
import config from "../../../config";



export const fetchSummary = async (params) => {
  // 'params' is a plain object with page, page_size, status, serial_number, etc.
  const token = getAuthToken();
  try {
    const response = await axios.get(`${config.inxOmsApiUrl}/crm/api/dashboard/communication_summary/`, {
      headers: { Authorization: `Token ${token}` },
      params, // pass the object
    });
    console.log("communication_summary: ", response);
    return response;
  } catch (error) {
    // handle error
    throw error;
  }
};

export const fetchCampaignPerformance = async (params) => {
  const token = getAuthToken();
  try {
    const response = await axios.get(`${config.inxOmsApiUrl}/crm/api/dashboard/campaign_performance`, {
      headers: { Authorization: `Token ${token}` },
      params, // Pass additional params like 'showactive' or others as needed
    });
    console.log("campaign_performance: ", response);
    return response;
  } catch (error) {
    // handle error
    throw error;
  }
};

export const fetchContentPerformance = async (params) => {
  const token = getAuthToken();
  try {
    const response = await axios.get(`${config.inxOmsApiUrl}/crm/api/dashboard/content_wise_performance`, {
      headers: { Authorization: `Token ${token}` },
      params, // Pass additional params like 'showactive' directly
    });
    console.log("content_wise_performance: ", response);
    return response; // Ensure we're returning the data object
  } catch (error) {
    console.error("API Error: ", error);
    throw error;
  }
};