import React, { useCallback, useEffect, useState } from 'react';
import { Button, Empty, Input, Select, Table, Tag, Tooltip } from 'antd';
import { fetchStocksApi } from './inventoryAPI';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCompanies } from '../../store/slices/companyBranchSlice';
import { BarcodeOutlined, ClearOutlined, FilterOutlined } from "@ant-design/icons";
import { truncateText } from '../../utils/utils';
import { fetchBrandsApi } from "../products/productSlice";

const { Option } = Select;

const InventoryStockList = ({ setErrorMessage, setSuccessMessage }) => {
    const dispatch = useDispatch();
    const { companies, loading: companiesLoading } = useSelector((state) => state.companyBranch);
    const { brands = [], brandsLoading, hasFetchedBrands } = useSelector((state) => state.products);

    const [stocks, setStocks] = useState([]);
    const [loading, setLoading] = useState(false);
    const [pagination, setPagination] = useState({ current: 1, pageSize: 10, total: 0 });

    // Consolidate all filter values into one state object
    const [userFilters, setUserFilters] = useState({
        brand: '',
        itemCode: '',
        company: '',
        branch: '',
    });
    const [filters, setFilters] = useState(userFilters);

    const [companyBranches, setCompanyBranches] = useState([]);

    // Fetch companies and brands on mount
    useEffect(() => {
        dispatch(fetchCompanies());

        if (!hasFetchedBrands) {
            const storedBrands = localStorage.getItem('brands');
            if (storedBrands) {
                try {
                    const parsedBrands = JSON.parse(storedBrands);
                    if (Array.isArray(parsedBrands) && parsedBrands.length > 0) {
                        return; // No need to dispatch if brands are already in localStorage
                    }
                } catch (error) {
                    console.error('Error parsing brands from localStorage', error);
                }
            }

            dispatch(fetchBrandsApi());
        }
    }, [dispatch, hasFetchedBrands]);

    // Update branches when a company is selected
    useEffect(() => {
        const company = companies.find((comp) => comp.id === userFilters.company);
        setCompanyBranches(company ? company.branches : []);
    }, [userFilters.company, companies]);

    // Fetch stocks
    const fetchStocks = useCallback(async () => {
        setLoading(true);

        const params = {
            page: pagination.current,
            page_size: pagination.pageSize,
            brand: filters.brand || undefined,
            item_code: filters.itemCode || undefined,
            company: filters.company || undefined,
            branch: filters.branch || undefined,
        };

        try {
            const data = await fetchStocksApi(params);
            setStocks(data.results);
            setPagination((prev) => ({ ...prev, total: data.count }));
        } catch (error) {
            setErrorMessage(error.message);
        } finally {
            setLoading(false);
        }
    }, [filters, pagination.current, pagination.pageSize, setErrorMessage]);

    // Fetch stocks when filters or pagination change
    useEffect(() => {
        fetchStocks();
    }, [fetchStocks]);

    // Handle filter changes
    const handleBrandChange = (value) => setUserFilters((prev) => ({ ...prev, brand: value }));
    const handleItemCodeChange = (e) => setUserFilters((prev) => ({ ...prev, itemCode: e.target.value }));
    const handleCompanyChange = (value) => setUserFilters((prev) => ({ ...prev, company: value }));
    const handleBranchChange = (value) => setUserFilters((prev) => ({ ...prev, branch: value }));

    // Apply filters
    const handleApplyFilters = () => {
        setFilters(userFilters);
        setPagination((prev) => ({ ...prev, current: 1 })); // Reset to first page
    };

    // Clear filters
    const handleClearFilters = () => {
        const resetFilters = {
            brand: '',
            itemCode: '',
            company: '',
            branch: '',
        };
        setUserFilters(resetFilters);
        setFilters(resetFilters);
        setPagination((prev) => ({ ...prev, current: 1 })); // Reset to first page
    };

    // Handle pagination change
    const handleTableChange = (pagination) => {
        setPagination({
            current: pagination.current,
            pageSize: pagination.pageSize,
            total: pagination.total,
        });
    };

    // Build a combined brand list from Redux or localStorage
    let brandList = brands; // default to Redux brand list
    if (!brands.length) {
        const storedBrands = localStorage.getItem('brands');
        if (storedBrands) {
            try {
                const parsedBrands = JSON.parse(storedBrands);
                if (Array.isArray(parsedBrands) && parsedBrands.length > 0) {
                    brandList = parsedBrands;
                }
            } catch (error) {
                console.error('Error parsing stored brands:', error);
            }
        }
    }

    const columns = [
        {
            title: 'Brand',
            dataIndex: 'brand_name',
            key: 'brand_name',
            render: (text) => (
                <Tooltip title={text || '-'}>
                    <span style={{ whiteSpace: 'nowrap' }}>{truncateText(text || '-', 15)}</span>
                </Tooltip>
            ),
        },
        {
            title: 'Item Code',
            dataIndex: 'item_code',
            key: 'item_code',
            render: (text) => (
                <Tooltip title={text || '-'}>
                    <span style={{ whiteSpace: 'nowrap' }}>{truncateText(text || '-', 20)}</span>
                </Tooltip>
            ),
        },
        {
            title: 'Quantity',
            dataIndex: 'quantity',
            key: 'quantity',
            align: 'right',
        },
        {
            title: 'Available Quantity',
            dataIndex: 'available_quantity',
            key: 'available_quantity',
            align: 'right',
        },
        {
            title: 'Company',
            dataIndex: 'company_name',
            key: 'company_name',
            align: 'center',
            render: (text) => (
                <Tooltip title={text || '-'}>
                    <span style={{ whiteSpace: 'nowrap' }}>{truncateText(text || '-', 20)}</span>
                </Tooltip>
            ),
        },
        {
            title: 'Branch',
            dataIndex: 'branch_name',
            key: 'branch_name',
            align: 'center',
            render: (text) => (
                <Tooltip title={text || '-'}>
                    <span style={{ whiteSpace: 'nowrap' }}>{truncateText(text || '-', 20)}</span>
                </Tooltip>
            ),
        },
    ];

    return (
        <div>
            {/* Filters */}
            <div style={{ display: 'flex', gap: '16px', marginBottom: '16px' }}>
                <Select
                    showSearch
                    placeholder="Search Brand"
                    optionFilterProp="children"
                    value={userFilters.brand || undefined}
                    onChange={handleBrandChange}
                    filterOption={(input, option) =>
                        option?.children?.toLowerCase().includes(input.toLowerCase())
                    }
                    notFoundContent={
                        <Empty
                            image={Empty.PRESENTED_IMAGE_SIMPLE}
                            description="No brands found."
                        />
                    }
                    style={{ width: 200 }}
                >
                    {brandList.map((brand) => (
                        <Option key={brand.id} value={brand.id}>
                            {brand.brand_name}
                        </Option>
                    ))}
                </Select>

                <Input
                    placeholder="Enter Item Code"
                    value={userFilters.itemCode}
                    onChange={handleItemCodeChange}
                    style={{ width: '200px' }}
                    prefix={<BarcodeOutlined />}
                />

                <Select
                    placeholder="Select Company"
                    value={userFilters.company || undefined}
                    onChange={handleCompanyChange}
                    style={{ width: '200px' }}
                    allowClear
                >
                    {companies.map((company) => (
                        <Option key={company.id} value={company.id}>
                            {company.company_name}
                        </Option>
                    ))}
                </Select>

                <Select
                    placeholder="Select Branch"
                    value={userFilters.branch || undefined}
                    onChange={handleBranchChange}
                    style={{ width: '200px' }}
                    allowClear
                    disabled={!userFilters.company}
                >
                    {companyBranches.map((branch) => (
                        <Option key={branch.id} value={branch.id}>
                            {branch.branch_name}
                        </Option>
                    ))}
                </Select>

                <Button
                    type="primary"
                    icon={<FilterOutlined />}
                    onClick={handleApplyFilters}
                >
                    Apply Filters
                </Button>

                <Button
                    icon={<ClearOutlined />}
                    onClick={handleClearFilters}
                    style={{
                        backgroundColor: '#f5222d',
                        borderColor: '#f5222d',
                        color: '#fff',
                    }}
                >
                    Clear Filters
                </Button>
            </div>

            <div style={{ marginBottom: '16px' }}>
                {filters.brand && <Tag>Brand: {brandList.find((b) => b.id === filters.brand)?.brand_name}</Tag>}
                {filters.itemCode && <Tag>Item Code: {filters.itemCode}</Tag>}
                {filters.company && (
                    <Tag>Company: {companies.find((c) => c.id === filters.company)?.company_name}</Tag>
                )}
                {filters.branch && (
                    <Tag>Branch: {companyBranches.find((b) => b.id === filters.branch)?.branch_name}</Tag>
                )}
            </div>

            {/* Table */}
            <Table
                columns={columns}
                dataSource={stocks}
                loading={loading}
                pagination={{
                    ...pagination,
                    showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
                }}
                onChange={handleTableChange}
                rowKey="id"
                size="small"
                locale={{
                    emptyText: (
                        <Empty
                            image={Empty.PRESENTED_IMAGE_SIMPLE}
                            description="No items available."
                        />
                    ),
                }}
            />
        </div>
    );
};

export default InventoryStockList;