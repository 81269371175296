import React, { useState, useEffect } from 'react';
import {Table, Spin, Alert, Card, Empty} from 'antd';
import { fetchAssigneeWisePerformance } from './leadsDashboardApi'; // Ensure API function exists

const AssigneeWisePerformance = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    showTotal: (total, range) => `Showing ${range[0]}-${range[1]} of ${total} items`
  });

  useEffect(() => {
    setLoading(true);
    fetchAssigneeWisePerformance()
      .then(response => {
        setData(response.data.assignee_performance); // Ensure correct API response structure
        setLoading(false);
      })
      .catch(err => {
        setError(err.toString());
        setLoading(false);
      });
  }, []);

  const columns = [
    {
      title: 'Assignee Name',
      dataIndex: 'assigned_to__username',
      key: 'assignee_name',
      sorter: (a, b) => a.assigned_to__full_name.localeCompare(b.assigned_to__full_name),
    },{
      title: 'Converted Leads',
      dataIndex: 'converted_leads',
      key: 'converted_leads',
      align: 'center',
      sorter: (a, b) => a.converted_leads - b.converted_leads,
    },
       {
      title: 'Conversion Ratio (%)',
      dataIndex: 'conversion_ratio',
      key: 'conversion_ratio',
      align: 'right',
      render: ratio => `${ratio.toFixed(2)}%`,
      sorter: (a, b) => a.conversion_ratio - b.conversion_ratio,
    },
    {
      title: 'Total Leads',
      dataIndex: 'total',
      key: 'total',
      align: 'center',
      sorter: (a, b) => a.total - b.total,
    },
    {
      title: 'Open Leads',
      dataIndex: 'open_leads',
      key: 'open_leads',
      align: 'center',
      sorter: (a, b) => a.open_leads - b.open_leads,
    },

    {
      title: 'Lost Leads',
      dataIndex: 'lost_leads',
      key: 'lost_leads',
      align: 'center',
      sorter: (a, b) => a.lost_leads - b.lost_leads,
    },

    {
      title: 'Lost Ratio (%)',
      dataIndex: 'lost_ratio',
      key: 'lost_ratio',
      align: 'right',
      render: ratio => `${ratio.toFixed(2)}%`,
      sorter: (a, b) => a.lost_ratio - b.lost_ratio,
    },
  ];

  if (loading) return <Spin />;
  if (error) return <Alert message="Error" description={error} type="error" showIcon />;

  return (
    <Card bordered={false} title="Assignee Wise Performance">
      <Table
        columns={columns}
        dataSource={data}
        rowKey="assigned_to"
        pagination={{
        ...pagination,
        showTotal: (total, range) => `Showing ${range[0]}-${range[1]} of ${total} items`,
        onChange: (page, pageSize) => setPagination({ ...pagination, current: page, pageSize })
      }}
      loading={loading}
      size="small"
      scroll={{ x: 'max-content' }}
      locale={{
        emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No records available."/>
      }}
      />
    </Card>
  );
};

export default AssigneeWisePerformance;
