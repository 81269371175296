import React, { useState, useEffect } from 'react';
import { Card, Spin, Alert, Row, Col, Statistic, Progress } from 'antd';
import { ArrowUpOutlined, ArrowDownOutlined } from '@ant-design/icons';
import { fetchSummary } from './dashboardApi';

const SummaryDashboard = () => {
  const [data, setData] = useState({
    attempted: 0,
    total_sent: 0,
    total_failed: 0,
    total_success: 0,
    responses_received: 0,
    success_rate: 0,
    response_rate: 0,
    active_campaigns: 0
  });
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetchSummary();
        setData(response.data);
        setIsLoading(false);
      } catch (error) {
        setError(error);
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  if (isLoading) return <Spin />;
  if (error) return <Alert message="Error" description="Failed to load data" type="error" showIcon />;

  return (
    <Row gutter={16}>
      <Col span={6}>
        <Card>
          <Statistic
            title="Messages Sent"
            value={data.total_sent}
          />
        </Card>
      </Col>
      <Col span={6}>
        <Card>
          <Statistic
            title="Success Rate"
            value={data.success_rate * 100}
            precision={2}
            suffix="%"
            prefix={<ArrowUpOutlined />}
            valueStyle={{ color: '#3f8600' }}
          />
          <Progress
            percent={(data.success_rate * 100).toFixed(0)}
            status="active"
            strokeColor="#52c41a"
            showInfo={false}
          />
        </Card>
      </Col>
      <Col span={6}>
        <Card>
          <Statistic
            title="Response Rate"
            value={data.response_rate * 100}
            precision={2}
            suffix="%"
            prefix={<ArrowDownOutlined />}
            valueStyle={{ color: '#cf1322' }}
          />
          <Progress
            percent={(data.response_rate * 100).toFixed(0)}
            status="active"
            strokeColor="#cf1322"
            showInfo={false}
          />
        </Card>
      </Col>
      <Col span={6}>
        <Card>
          <Statistic
            title="Active Campaigns"
            value={data.active_campaigns}
          />
        </Card>
      </Col>
    </Row>
  );
};

export default SummaryDashboard;
