import React from 'react';
import { Card, Tabs, Row, Col } from 'antd';
import LeadsSummary from './LeadsSummary';
import OpenLeadsByStatus from './OpenLeadsByStatus';
import SourceWiseConversion from './SourceWiseConversion';
import BranchWisePerformance from './BranchWisePerformance';
import AssigneeWisePerformance from './AssigneeWisePerformance';

const { TabPane } = Tabs;

const LeadsDashboard = () => {
  const tabItems = [
    {
      label: "Source Wise Conversion",
      key: "1",
      children: <SourceWiseConversion />
    },
    {
      label: "Branch Wise Performance",
      key: "2",
      children: <BranchWisePerformance />
    },
    {
      label: "Assignee Wise Performance",
      key: "3",
      children: <AssigneeWisePerformance />
    },
  ];

  return (
    <>
      <Row gutter={16}>
        <Col xs={24} md={12}>
          <LeadsSummary />
        </Col>
        <Col xs={24} md={12}>
          <OpenLeadsByStatus />
        </Col>
      </Row>
      <Card bordered={false}>
        <Tabs defaultActiveKey="1" items={tabItems} />
      </Card>
    </>
  );
};

export default LeadsDashboard;
