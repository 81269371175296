import React, {useEffect, useState} from 'react';
import {
    Button,
    Card,
    Descriptions,
    Drawer,
    List,
    Pagination,
    Popconfirm,
    Progress,
    Space,
    Tag,
    Typography,
    Upload
} from 'antd';
import {CloseOutlined, CloudUploadOutlined, EditOutlined, UploadOutlined} from '@ant-design/icons';
import {executeEvent, fetchCommunicationLogs, scheduleEvent, uploadEventCustomerList} from "./crmApis";
import {fetchTaskProgress} from "../tasks/taskApis";
import {useMessageContext} from "../../components/MessageContext";
import {formatDateTime} from "../../utils/dateUtils";

const {Text, Title} = Typography;

const getStatusColor = (status) => {
    switch (status) {
        case 'pending':
            return 'orange';
        case 'sent':
            return 'blue';
        case 'delivered':
            return 'green';
        case 'failed':
            return 'red';
        default:
            return 'default';
    }
};

const EventDetailsDrawer = ({visible, onClose, event, onEditEvent, refreshEvents}) => {
    const [logs, setLogs] = useState([]);
    const messageContext = useMessageContext();

    const [pagination, setPagination] = useState({current: 1, pageSize: 10, total: 0});
    const [loading, setLoading] = useState(false);

    const [file, setFile] = useState(null);
    const [uploading, setUploading] = useState(false);
    const [taskProgress, setTaskProgress] = useState(null);
    const [progressInterval, setProgressInterval] = useState(null);
    const [showUploadSection, setShowUploadSection] = useState(false); // Initially false: show the CTA

    useEffect(() => {
        if (visible && event) {
            fetchLogs(event.id, pagination.current);
        }
    }, [visible, event, pagination.current]);

    useEffect(() => {
        return () => {
            if (progressInterval) {
                clearInterval(progressInterval);
            }
        };
    }, [progressInterval]);


    const fetchLogs = async (eventId, page) => {
        setLoading(true);
        try {
            const data = await fetchCommunicationLogs({
                event: eventId,
                page,
                page_size: pagination.pageSize
            });
            setLogs(data.results || []);
            setPagination(prev => ({...prev, total: data.count}));
        } catch (error) {
            setLogs([]);
            console.error('Failed to fetch communication logs', error);
        } finally {
            setLoading(false);
        }
    };

    const handlePageChange = (page, pageSize) => {
        setPagination(prev => ({...prev, current: page, pageSize}));
    };

    const handleFileChange = (info) => {
        // Only store file if it's not currently uploading or done
        if (info.file.status === 'done' || info.file.status === 'uploading') return;
        setFile(info.file);
    };

    const handleUpload = async () => {
        if (!file) {
            messageContext.error('Please select a file before uploading.');
            return;
        }
        setUploading(true);
        // Hide the upload section once processing begins
        setShowUploadSection(false);
        // Reset any previous progress data
        setTaskProgress(null);
        try {
            const response = await uploadEventCustomerList(event.id, file);
            // Start polling for progress using the celery-progress endpoint
            // checkProgress(response.task_id);
            const interval = setInterval(() => checkProgress(response.task_id, interval), 500);
            setProgressInterval(interval);
        } catch (error) {
            messageContext.error(`Failed to upload file: ${error.message}`);
            setShowUploadSection(true); // Show upload section for retry
        } finally {
            setUploading(false);
        }
    };

    const checkProgress = async (taskId, interval) => {
        try {
            const progressData = await fetchTaskProgress(taskId);

            // Update the progress state
            setTaskProgress(prevState => ({
                ...prevState,
                progress: progressData.progress,
                state: progressData.state,
                details: progressData.details || '',
                result: progressData.result?.message || ''
            }));

            // Handling based on task state
            switch (progressData.state) {
                case 'FAILURE':
                    messageContext.error(`Upload failed: ${progressData.details || "Unknown error occurred"}`);
                    clearInterval(interval);
                    setProgressInterval(null);
                    setShowUploadSection(true); // Show the upload section again for retry
                    break;
                case 'SUCCESS':
                    if (progressData.result?.error) {
                        // Handle error within a successful state
                        messageContext.error(`Upload completed with errors: ${progressData.result.error}`);
                        setTaskProgress(prevState => ({
                            ...prevState,
                            state: 'ERROR', // Custom state to reflect backend inconsistency
                            details: progressData.result.error,
                            result: progressData.result.error // Assuming error is descriptive enough
                        }));
                    } else {
                        // Successful upload
                        messageContext.success(`Upload completed successfully! ${progressData.result?.message || ''}`);
                        setTaskProgress(prevState => ({
                            ...prevState,
                            state: progressData.state,
                            details: progressData.details || '',
                            result: progressData.result?.message || '',
                            progress: {...prevState.progress, total: progressData.progress.total} // Update total customers processed
                        }));
                        console.log("progressData: ", progressData);
                        refreshEvents();
                    }
                    clearInterval(interval);
                    setProgressInterval(null);
                    break;

                default:
                    // For ongoing states like PENDING, PROGRESS, etc., let it be handled by continuous polling
                    break;
            }
        } catch (error) {
            console.error('Failed to fetch task progress:', error);
            messageContext.error('Error fetching task progress. Please try again.');
            clearInterval(interval);
            setProgressInterval(null);
            setShowUploadSection(true); // Show upload section for retry
        }
    };


    const handleExecuteEvent = async () => {
        try {
            setLoading(true);
            const result = await executeEvent(event.id);
            messageContext.success('Event execution started successfully.');
            console.log('Execution result:', result);
        } catch (error) {
            console.error('Error executing event:', error);
            messageContext.error('Failed to start event execution. Please try again.');
        } finally {
            setLoading(false);
        }
    };


    const handleScheduleEvent = async () => {
        try {
            setLoading(true);
            const result = await scheduleEvent(event.id);
            messageContext.success('Event scheduled successfully.');
            console.log('schedule result:', result);
            refreshEvents();
        } catch (error) {
            console.error('Error scheduling event:', error);
            messageContext.error('Failed to schedule event. Please try again.');
        } finally {
            setLoading(false);
        }
    };


    return (
        <Drawer
            title={
                <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                    <Title level={4} style={{margin: 0}}>Event Details</Title>
                    <Space wrap>
                    {event?.status === 'scheduled' && (
                        <Popconfirm
                            title="Are you sure you want to execute this event?"
                            onConfirm={handleExecuteEvent}
                            okText="Yes"
                            cancelText="No"
                            disabled={loading}
                        >
                            <Button
                                type="primary"
                                loading={loading}
                                style={{
                                    marginLeft: 10,
                                    backgroundColor: '#52c41a',
                                    borderColor: '#52c41a'
                                }} // Using Ant Design green
                            >
                                Execute Event
                            </Button>
                        </Popconfirm>

                    )}
                        {event?.status === 'draft' && (
                        <Popconfirm
                            title="Are you sure you want to schedule this event?"
                            onConfirm={handleScheduleEvent}
                            okText="Yes"
                            cancelText="No"
                            disabled={loading}
                        >
                            <Button
                                type="primary"
                                loading={loading}
                                style={{
                                    marginLeft: 10,
                                    backgroundColor: '#52c41a',
                                    borderColor: '#52c41a'
                                }} // Using Ant Design green
                            >
                                Schedule Event
                            </Button>
                        </Popconfirm>

                    )}
                    {event?.status === 'draft' && (
                        <Button onClick={() => onEditEvent(event)} icon={<EditOutlined />} style={{ marginRight: 8 }}>Edit</Button>
                    )}
                    <Button onClick={onClose} icon={<CloseOutlined/>}>Close</Button>
                        </Space>
                </div>
            }
            width="75%"
            onClose={onClose}
            open={visible}
            styles={{body: {paddingBottom: 80}}} // Use new API for body styling

        >
            {event && (
                <>
                    <Descriptions bordered column={2} size="small" style={{marginBottom: 10}}>
                        <Descriptions.Item label="Event ID">{event.id || 'N/A'}</Descriptions.Item>
                        <Descriptions.Item label="Event Name">{event.event_name || 'N/A'}</Descriptions.Item>
                        <Descriptions.Item label="Campaign">{event.campaign_name || 'N/A'}</Descriptions.Item>
                        <Descriptions.Item label="Content">{event.content?.title || 'N/A'}</Descriptions.Item>
                        <Descriptions.Item label="Status">
                            <Tag color="blue">{event.status}</Tag>
                        </Descriptions.Item>
                        <Descriptions.Item
                            label="Scheduled Date">{formatDateTime(event.scheduled_date)}</Descriptions.Item>
                        <Descriptions.Item
                            label="Execution Date">{formatDateTime(event.executed_date) || 'N/A'}</Descriptions.Item>
                        <Descriptions.Item
                            label="Completion Date">{formatDateTime(event.completion_date) || 'N/A'}</Descriptions.Item>
                    </Descriptions>

                    {/* Upload CTA: Shown when not uploading and upload section is not visible */}
                    {event.status === 'draft' &&
                        !uploading &&
                        !showUploadSection &&
                        !taskProgress && (
                            <Button
                                type="primary"
                                icon={<CloudUploadOutlined/>}
                                onClick={() => setShowUploadSection(true)}
                                block
                                style={{marginBottom: 10}}
                            >
                                Upload Customers
                            </Button>
                        )}


                    {/* Upload Section: Shown when showUploadSection is true */}
                    {showUploadSection && (
                        <Card title="Upload Customer List" bordered={false}
                              style={{marginBottom: 20, textAlign: 'center'}}>
                            <Upload
                                accept=".xls,.xlsx"
                                beforeUpload={() => false}
                                onChange={handleFileChange}
                                maxCount={1}
                                showUploadList={{showRemoveIcon: true}}
                            >
                                <Button icon={<UploadOutlined/>} type="primary">
                                    Click to Upload
                                </Button>
                            </Upload>
                            {file && (
                                <p style={{marginTop: 10, fontSize: 14}}>
                                    <strong>Selected:</strong> {file.name}
                                </p>
                            )}
                            <Button
                                type="primary"
                                onClick={handleUpload}
                                loading={uploading}
                                disabled={!file}
                                style={{marginTop: 10, width: '100%'}}
                            >
                                Process File
                            </Button>
                        </Card>
                    )}

                    {/* Upload Progress Section: Always shown if taskProgress exists */}
                    {taskProgress && (
                        <Card title="Upload Progress" bordered={false} style={{marginBottom: 20}}>
                            <Progress
                                percent={taskProgress.progress.percent}
                                status={
                                    taskProgress.state === 'FAILURE' ? 'exception' :
                                        taskProgress.state === 'SUCCESS' ? 'success' : 'active'
                                }
                                strokeColor={
                                    taskProgress.state === 'FAILURE' ? '#ff4d4f' : // Red color on failure
                                        taskProgress.state === 'SUCCESS' ? '#52c41a' : // Green color on success
                                            '#1890ff' // Default blue color for active state
                                }
                            />
                            <div style={{marginTop: 16, fontSize: '16px'}}>
                                <strong>Status:</strong> {taskProgress.state === 'PENDING' ? 'Initiating the upload process...' : taskProgress.state}
                            </div>
                            {taskProgress.state === 'PROGRESS' && (
                                <div style={{marginTop: 8, fontSize: '14px'}}>
                                    <strong>Processing:</strong> {`${taskProgress.progress.current} customers processed out of ${taskProgress.progress.total}`}
                                </div>
                            )}
                            {taskProgress.result.error && (
                                <div style={{marginTop: 8, fontSize: '14px', color: '#888'}}>
                                    <strong>Result:</strong> {taskProgress.result.error}
                                </div>
                            )}
                            {taskProgress.result.message && (
                                <div style={{marginTop: 8, fontSize: '14px', color: '#888'}}>
                                    <strong>Result:</strong> {taskProgress.result + (taskProgress.state === 'SUCCESS' ? ` ${taskProgress.progress.total} customers processed and uploaded.` : '')}
                                </div>
                            )}
                            {/*{taskProgress.result.message && (*/}
                            {/*    <div style={{marginTop: 8, fontSize: '14px', color: '#888'}}>*/}
                            {/*        <strong>Result:</strong> {taskProgress.result + (taskProgress.state === 'SUCCESS' ? ` ${taskProgress.progress.total} customers processed and uploaded.` : '')}*/}
                            {/*    </div>*/}
                            {/*)}*/}
                            {taskProgress.details && (
                                <div style={{marginTop: 8, fontSize: '14px', color: 'red'}}>
                                    <strong>Error Details:</strong> {taskProgress.details}
                                </div>
                            )}
                        </Card>
                    )}

                    {/* Communication Logs */}
                    <Title level={4}>Communication Logs</Title>
                    <List
                        dataSource={logs}
                        loading={loading}
                        bordered
                        size="small"
                        renderItem={item => (
                            <List.Item style={{padding: '12px 16px', borderBottom: '1px solid #e8e8e8'}}>
                                <List.Item.Meta
                                    title={
                                        <Text strong style={{fontSize: '14px', color: '#1890ff'}}>
                                            {item.customer_name || 'Unknown'}
                                        </Text>
                                    }
                                    description={
                                        <Text type="secondary" style={{fontSize: '12px'}}>
                                            📞 {item.mobile_number || 'N/A'}
                                        </Text>
                                    }
                                />
                                <Space direction="vertical" size="small" align="end">
                                    <Tag color={getStatusColor(item.status)} style={{fontSize: '12px'}}>
                                        {item.status.toUpperCase()}
                                    </Tag>
                                    <Text type="secondary" style={{fontSize: '12px'}}>
                                        <strong>Sent Date:</strong> {item.sent_date || 'Not Sent'}
                                    </Text>
                                </Space>
                            </List.Item>
                        )}
                    />
                    <Pagination
                        current={pagination.current}
                        total={pagination.total}
                        onChange={handlePageChange}
                        pageSize={pagination.pageSize}
                        showSizeChanger={false}
                        showQuickJumper={false}
                    />
                </>
            )}
        </Drawer>
    );
};

export default EventDetailsDrawer;
