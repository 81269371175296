// UpdateFollowUpModal.js
import React, {useEffect, useState} from 'react';
import {DatePicker, Form, Input, Modal, Select} from 'antd';
import moment from 'moment';
import {useDispatch, useSelector} from "react-redux";
import {fetchSaleLostReasons,} from "../../store/slices/locationSlice";
import {useMessageContext} from "../../components/MessageContext";

const {Option} = Select;
const {TextArea} = Input;

// Define status choices (adapt as needed)
const STATUS_CHOICES = [
    {value: 'new', label: 'New'},
    {value: 'contacted', label: 'Contacted'},
    {value: 'qualified', label: 'Qualified'},
    {value: 'proposal', label: 'Proposal Sent'},
    {value: 'converted', label: 'Converted'},
    {value: 'lost', label: 'Lost'},
];

const UpdateFollowUpModal = ({visible, onClose, onSubmit, initialValues = {}}) => {
    const messageContext = useMessageContext();
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const [status, setStatus] = useState(initialValues.status || '');

    const {saleLostReasons} = useSelector((state) => state.configuration);

    useEffect(() => {
        if (saleLostReasons.length === 0) dispatch(fetchSaleLostReasons());
    }, [dispatch, saleLostReasons]);

    // When the modal opens or initialValues change, update the form fields.
    useEffect(() => {

        if (form) {
            if (!visible) return;
            console.log("Form is connected, setting up default values");
            form.resetFields();

            form.setFieldsValue({
                ...initialValues,
                // If follow_up_date exists, convert it to a moment object
                follow_up_date: initialValues.follow_up_date ? moment(initialValues.follow_up_date) : null,
            });
        }
        setStatus(initialValues.status || '');
    }, [initialValues, form]);

    const handleStatusChange = (value) => {
        setStatus(value);
    };

    const handleFinish = (values) => {
        onSubmit(values);
    };

    return (
        <Modal
            title="Update Follow Up Details"
            placement="right"
            closable={true}
            onClose={() => {
                form.resetFields();
                onClose();
            }}
            open={visible}
            onOk={() => form.submit()}
            onCancel={() => {

                form.resetFields();
                onClose();
            }}
            okText="Update Follow Up Details"
            width="75%"
            destroyOnClose
        >
            <Form form={form} layout="vertical" onFinish={handleFinish}>
                <Form.Item
                    name="status"
                    label="Status"
                    rules={[{required: true, message: 'Please select a status'}]}
                >
                    <Select placeholder="Select status" onChange={handleStatusChange}>
                        {STATUS_CHOICES.map(item => (
                            <Option key={item.value} value={item.value}>
                                {item.label}
                            </Option>
                        ))}
                    </Select>
                </Form.Item>

                <Form.Item
                    name="details"
                    label="Details"
                    rules={[{required: true, message: 'Please enter details'}]}
                >
                    <TextArea rows={3} placeholder="Enter details"/>
                </Form.Item>

                {/* If the status is not converted or lost, follow-up date/time is mandatory */}
                {status !== 'converted' && status !== 'lost' && (
                    <Form.Item
                        name="follow_up_date"
                        label="Follow-up Date & Time"
                        rules={[{required: true, message: 'Please select follow-up date and time'}]}
                    >
                        <DatePicker showTime style={{width: '100%'}}
                                    format="YYYY-MM-DD HH:mm"  // Specify the format directly here

                        />
                    </Form.Item>
                )}

                {/* If the status is lost, sale lost reason is mandatory */}
                {status === 'lost' && (
                    <Form.Item
                        name="lost_reason"
                        label="Sale Lost Reason"
                        rules={[{required: true, message: 'Please select sale lost reason'}]}
                    >
                        <Select placeholder="Select lost reason">
                            {saleLostReasons.map(reason => (
                                <Option key={reason.id} value={reason.value}>
                                    {reason.reason}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>
                )}


            </Form>
        </Modal>
    );
};

export default UpdateFollowUpModal;
