import React, { useCallback, useEffect, useState } from 'react';
import { Button, Col, Input, Row, Select } from 'antd';
import CustomersTable from './CustomersTable';
import { fetchCustomersApi } from './customerApi'; // Ensure path is correct
import { ClearOutlined, SearchOutlined } from '@ant-design/icons';

const { Option } = Select;

const CustomerList = () => {
    const [customers, setCustomers] = useState([]);
    const [loading, setLoading] = useState(false);
    const [userFilters, setUserFilters] = useState({
        name: '',
        mobile_number: '',
    gstin: '',
        email: '',
        customer_type: '',
        territory: ''
    });
    const [filters, setFilters] = useState(userFilters);
    const [pagination, setPagination] = useState({ current: 1, pageSize: 10, total: 0 });

    const fetchCustomers = useCallback(async () => {
        setLoading(true);
        try {
            const params = {
                ...filters,
                page: pagination.current,
                pageSize: pagination.pageSize, // Make sure this matches your API parameter
            };
            const data = await fetchCustomersApi(params);
            setCustomers(data.results);
            setPagination(prev => ({ ...prev, total: data.count })); // Ensure this matches your API response
        } catch (error) {
            console.error('Error loading customers:', error);
        } finally {
            setLoading(false);
        }
    }, [filters, pagination.current, pagination.pageSize]);

    // Initial fetch and fetch only on page change or explicit filter application
    useEffect(() => {
        fetchCustomers();
    }, [pagination.current, fetchCustomers, filters]);

    const handleFilterChange = (field, value) => {
        setUserFilters(prev => ({ ...prev, [field]: value }));
    };

    const handlePageChange = (page, pageSize) => {
        setPagination(prev => ({ ...prev, current: page, pageSize }));
    };

    const handleClearFilters = () => {
        const resetFilters = {
            name: '',
            mobile_number: '',
    gstin: '',
            email: '',
            customer_type: '',
            territory: ''
        };
        setUserFilters(resetFilters);
        setFilters(resetFilters);
        setPagination(prev => ({ ...prev, current: 1, pageSize: 10, total: prev.total }));
    };

    const handleApplyFilters = () => {
        setFilters(userFilters);
        setPagination(prev => ({ ...prev, current: 1 })); // Reset to first page on applying filters
    };

    return (
        <div style={{ padding: '24px' }}>
            <Row gutter={[16, 16]}>
                <Col xs={24} sm={12} md={8} lg={8}>
                    <Input
                        placeholder="Search by Name"
                        value={userFilters.name}
                        onChange={e => handleFilterChange('name', e.target.value)}
                        style={{ width: '100%' }}
                    />
                </Col>
                <Col xs={24} sm={12} md={8} lg={8}>
          <Input
            placeholder="Search by Mobile Number"
            value={userFilters.mobile_number}
            onChange={e => handleFilterChange('mobile_number', e.target.value)}
            style={{ width: '100%' }}
          />
        </Col>
                <Col xs={24} sm={12} md={8} lg={8}>
          <Input
            placeholder="Search by GSTIN"
            value={userFilters.gstin}
            onChange={e => handleFilterChange('gstin', e.target.value)}
            style={{ width: '100%' }}
          />
        </Col>
                </Row>

      {/* Second Row: Email, Customer Type, Territory */}
      <Row gutter={[16, 16]} style={{ marginTop: 16 }}>
                <Col xs={24} sm={12} md={8} lg={8}>
                    <Input
                        placeholder="Search by Email"
                        value={userFilters.email}
                        onChange={e => handleFilterChange('email', e.target.value)}
                        style={{ width: '100%' }}
                    />
                </Col>
                <Col xs={24} sm={12} md={8} lg={8}>
                    <Select
                        placeholder="Select Customer Type"
                        value={userFilters.customer_type}
                        onChange={value => handleFilterChange('customer_type', value)}
                        style={{ width: '100%' }}
                        allowClear
                    >
                        <Option value="individual">Individual</Option>
                        <Option value="business">Business</Option>
                    </Select>
                </Col>
                <Col xs={24} sm={12} md={8} lg={8}>
                    <Select
                        placeholder="Select Territory"
                        value={userFilters.territory}
                        onChange={value => handleFilterChange('territory', value)}
                        style={{ width: '100%' }}
                        allowClear
                    >
                        {/* Dynamically load options here */}
                    </Select>
                </Col>
            </Row>

            <Row gutter={[16, 16]} justify="end" style={{ marginTop: 16 }}>
                <Col>
                    <Button
                        type="primary"
                        icon={<SearchOutlined />}
                        onClick={handleApplyFilters}
                    >
                        Apply Filter
                    </Button>
                </Col>
                <Col>
                    <Button
                        icon={<ClearOutlined />}
                        onClick={handleClearFilters}
                        danger
                    >
                        Clear Filter
                    </Button>
                </Col>
            </Row>

            <CustomersTable
                data={customers}
                loading={loading}
                pagination={{
                    current: pagination.current,
                    pageSize: pagination.pageSize,
                    total: pagination.total,
                }}
                onPageChange={handlePageChange}
            />
        </div>
    );
};

export default CustomerList;
