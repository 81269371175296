import React from 'react';
import {Button, Descriptions, Drawer, Popconfirm, Space, Table, Tag, Typography} from 'antd';
import {PAYMENT_STATUS_COLORS} from '../common/paymentStatusColors';
import {formatRupee} from "../../utils/utils";
import {formatDate} from "../../utils/dateUtils";
import {confirmPaymentApi} from "./customerPaymentsApi";

const {Title} = Typography;

const CustomerPaymentDetailsDrawer = ({
                                          visible,
                                          onClose,
                                          payment,
                                          onConfirmPayment,
                                          onEditPayment,
                                          setSuccessMessage,
                                          setErrorMessage,
                                          setInfoMessage
                                      }) => {

    // 1) Get user role from localStorage
    const user = JSON.parse(localStorage.getItem('user'));
    const userRole = user?.user_role;
    const canEditAsAdmin = ['super_admin', 'admin'].includes(userRole);


    const showEditButton = () => {
    if (payment.payment_status === 'draft') {
        return canEditPayment; // Anyone with edit permissions can edit if draft
    } else if (['pending', 'requested', 'received'].includes(payment.payment_status)) {
        return canEditAsAdmin; // Only super admins or admins can edit if pending, requested, or received
    }
    return false; // No one can edit otherwise
};

    // 2) Define statuses/roles allowed for editing
    const statusesAllowedForEdit = ['draft', 'pending', 'requested', 'received'];
    const rolesAllowedForEdit = ['super_admin', 'admin']; // example roles

    // 3) Compute if user can edit
    const canEditPayment =
        payment &&
        statusesAllowedForEdit.includes(payment.payment_status) &&
        rolesAllowedForEdit.includes(userRole);


    const confirmButtonVisible =
        payment?.payment_status === 'draft' || payment?.payment_status === 'requested';

    const settlementColumns = [
        {title: 'Sale Order', dataIndex: 'sale_order_number', key: 'sale_order_number', render: text => text || 'N/A'},
        {title: 'Invoice', dataIndex: 'invoice_number', key: 'invoice_number', render: text => text || 'N/A'},

        {title: 'Amount', dataIndex: 'amount', key: 'amount', align: 'right', render: amount => formatRupee(amount)},
        {title: 'Date', dataIndex: 'settlement_date', key: 'settlement_date', render: date => formatDate(date)},
    ];

    const handleConfirmPayment = async (paymentId, paymentMethod) => {
        if (paymentMethod === 'credit') {
            setErrorMessage('Payments made via credit cannot be confirmed.');
            return;
        }
        try {
            // API call to confirm the order
            await confirmPaymentApi(paymentId); // Replace with actual API function
            setSuccessMessage('Order confirmed successfully!');
        } catch (error) {
            setErrorMessage('Failed to confirm order');
        }
    };

    // Optional: local handler to edit. Alternatively, call `onEditPayment` from parent
    const handleEditPayment = () => {
        if (onEditPayment) {
            onEditPayment(payment);
        } else {
            setInfoMessage('Edit Payment not implemented yet.');
        }
    };


    return (
        <Drawer
            title={
                <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                    <Title level={5} style={{margin: 0}}>Payment Details</Title>
                    {showEditButton()  && (
                        <Button type="default" onClick={handleEditPayment}>
                            Edit Payment
                        </Button>
                    )}
                    {confirmButtonVisible && (
                        <Popconfirm
                            title="Are you sure you want to confirm this payment?"
                            onConfirm={() => handleConfirmPayment(payment.id, payment.payment_method)}
                            okText="Yes"
                            cancelText="No"
                        >
                            <Button type="primary">Confirm Payment</Button>
                        </Popconfirm>
                    )}
                </div>
            }
            width="70%"
            onClose={onClose}
            open={visible}
            footer={
                <div style={{textAlign: 'right'}}>
                    <Button onClick={onClose} style={{marginRight: 8}}>Close</Button>
                </div>
            }
        >
            {payment ? (
                <>
                    <Descriptions title="Payment Information" layout="vertical" bordered size="small" column={2}>
                        <Descriptions.Item label="Payment ID">{payment.id}</Descriptions.Item>
                        <Descriptions.Item label="Date">{formatDate(payment.payment_date)}</Descriptions.Item>
                        <Descriptions.Item label="Customer">{payment.customer_name || 'N/A'}</Descriptions.Item>
                        <Descriptions.Item label="Order #">{payment.sale_order_number || 'N/A'}</Descriptions.Item>
                        <Descriptions.Item label="Amount">{formatRupee(payment.amount)}</Descriptions.Item>
                        <Descriptions.Item label="Status">
                            <Tag
                                color={PAYMENT_STATUS_COLORS[payment.payment_status]}>{payment.payment_status_display}</Tag>
                        </Descriptions.Item>

                        <Descriptions.Item label="Payment Method">{payment.payment_method_display}</Descriptions.Item>
                        <Descriptions.Item
                            label="Transaction #">{payment.transaction_number || 'N/A'}</Descriptions.Item>
                        <Descriptions.Item label="Account">{payment.account_name || 'N/A'}</Descriptions.Item>
                        <Descriptions.Item label="Payment Type">{payment.payment_type_display}</Descriptions.Item>


                        <Descriptions.Item
                            label="Promised Date">{formatDate(payment.promised_date) || 'N/A'}</Descriptions.Item>
                        <Descriptions.Item
                            label="Transaction Details">{payment.transaction_details || 'N/A'}</Descriptions.Item>
                        <Descriptions.Item label="Company">{payment.company_name || 'N/A'}</Descriptions.Item>
                        <Descriptions.Item label="Branch">{payment.branch_name || 'N/A'}</Descriptions.Item>
                        <Descriptions.Item label="Notes">{payment.note || 'No notes provided'}</Descriptions.Item>
                        <Descriptions.Item label="Attachment">
        {payment.attachment ? (
            <a href={payment.attachment} target="_blank" rel="noopener noreferrer">View Attachment</a>
        ) : (
            'No attachment'
        )}
    </Descriptions.Item>

                    </Descriptions>


                    {payment.settlements?.length > 0 && (
                        <>
                            <Title level={5} style={{marginTop: 20}}>Settlements</Title>
                            <Table
                                columns={settlementColumns}
                                dataSource={payment.settlements}
                                rowKey="id"
                                pagination={false}
                                size="small"
                            />
                        </>
                    )}
                    <Descriptions title="Audit Information" layout="vertical" bordered size="small" column={2}
                                  style={{marginTop: 20}}>
                        <Descriptions.Item label="Created Date">{formatDate(payment.created_at)}</Descriptions.Item>
                        <Descriptions.Item label="Created By">{payment.created_by_name || 'N/A'}</Descriptions.Item>
                        <Descriptions.Item label="Updated Date">{formatDate(payment.updated_at)}</Descriptions.Item>
                        <Descriptions.Item label="Updated By">{payment.updated_by_name || 'N/A'}</Descriptions.Item>
                        <Descriptions.Item
                            label="Received Date">{formatDate(payment.payment_date) || 'N/A'}</Descriptions.Item>
                        <Descriptions.Item label="Received By">{payment.received_by_name || 'N/A'}</Descriptions.Item>

                    </Descriptions>
                </>
            ) : (
                <Space direction="vertical" style={{width: '100%', textAlign: 'center', marginTop: 50}}>
                    <Title level={5}>No payment details available.</Title>
                </Space>
            )}
        </Drawer>
    );
};

export default CustomerPaymentDetailsDrawer;
