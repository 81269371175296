// LeadsList.js
import React, {useCallback, useEffect, useState} from 'react';
import {Button, Col, Input, Row, Select, Space} from 'antd';
import LeadsTable from './LeadsTable';
import LeadDetailsDrawer from './LeadDetailsDrawer';
import LeadRegistrationForm from './LeadRegistrationForm';
import {addLeadInteraction, fetchLeadDetails, fetchLeadsApi} from './crmApis'; // Adjust the import path as needed
import {ClearOutlined, PlusOutlined, SearchOutlined} from '@ant-design/icons';
import UpdateFollowUpModal from "./UpdateFollowUpModal";
import {useMessageContext} from "../../components/MessageContext";

const {Option} = Select;

const LeadsList = () => {
    const messageContext = useMessageContext();
    const [viewMode, setViewMode] = useState('list'); // 'list' or 'create'
    // States for leads_dashboard, loading, selected lead (for the drawer), and visibility
    const [leads, setLeads] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selectedLeadId, setSelectedLeadId] = useState(null);
    const [selectedLead, setSelectedLead] = useState(null);
    const [drawerVisible, setDrawerVisible] = useState(false);
    const [updateModalVisible, setUpdateModalVisible] = useState(false);

    const [refreshInteractions, setRefreshInteractions] = useState(false);

    // UI filters (changed on every keystroke) versus applied filters
    const [userFilters, setUserFilters] = useState({
        customer_name: '',
        mobile_number: '',
        status: '',
        source: '',
        assigned_to: '',
    });
    const [filters, setFilters] = useState(userFilters);

    // Pagination state
    const [pagination, setPagination] = useState({current: 1, pageSize: 10, total: 0});

    // Fetch leads_dashboard from API
    const fetchLeads = useCallback(async () => {
        setLoading(true);
        try {
            const params = {
                ...filters,
                page: pagination.current,
                pageSize: pagination.pageSize,
            };
            const data = await fetchLeadsApi(params);
            setLeads(data.results);
            setPagination(prev => ({...prev, total: data.count}));
        } catch (error) {
            console.error('Error fetching leads_dashboard:', error);
            messageContext.error('Error fetching leads_dashboard:', error);
        } finally {
            setLoading(false);
        }
    }, [filters, pagination.current, pagination.pageSize]);

    // Fetch leads_dashboard when applied filters or pagination changes
    useEffect(() => {
        fetchLeads();
    }, [fetchLeads]);

    // Handler for filter field changes
    const handleFilterChange = (field, value) => {
        setUserFilters(prev => ({...prev, [field]: value}));
    };

    // Apply filters: update the applied filters state and reset to first page
    const handleApplyFilters = () => {
        setFilters(userFilters);
        setPagination(prev => ({...prev, current: 1}));
    };

    // Clear filters: reset both UI filters and applied filters
    const handleClearFilters = () => {
        const resetFilters = {
            customer_name: '',
            mobile_number: '',
            status: '',
            source: '',
            assigned_to: '',
        };
        setUserFilters(resetFilters);
        setFilters(resetFilters);
        setPagination(prev => ({...prev, current: 1}));
    };

    // Update pagination (e.g. when the page changes)
    const handlePageChange = (page, pageSize) => {
        setPagination(prev => ({...prev, current: page, pageSize}));
    };

    // Open the drawer and fetch the latest lead details
    const openDrawer = (leadId) => {
        setSelectedLeadId(leadId); // Store only the ID
        setDrawerVisible(true);
    };

    // Close the details drawer
    const closeDrawer = () => {
        setDrawerVisible(false);
        setSelectedLead(null);
        setRefreshInteractions(false);
    };

    const handleOpenUpdateModal = () => {
        setUpdateModalVisible(true);
    };

    const handleCloseUpdateModal = () => {
        setUpdateModalVisible(false);
    };

    // ** Fetch single lead details when viewing/editing a lead **
    const fetchLeadDetailsById = async (leadId) => {
        console.log('Fetching details for lead:', leadId);
        try {
            const updatedLead = await fetchLeadDetails(leadId);
            console.log('Fetched lead details:', updatedLead);
            setSelectedLead(updatedLead);
        } catch (error) {
            console.error("Error fetching lead details:", error);
            messageContext.error('Error fetching lead details:', error);
        }
    };


    // ** Refresh all data (leads_dashboard list and lead details if drawer is open) **
    const refreshData = async () => {
        fetchLeads(); // Refresh leads_dashboard list
        if (selectedLeadId) {
            fetchLeadDetailsById(selectedLeadId); // Refresh details if a lead is being viewed
        }
    };

    useEffect(() => {
        if (selectedLeadId && drawerVisible) {
            fetchLeadDetailsById(selectedLeadId);
        }
    }, [selectedLeadId, drawerVisible]);


    const handleUpdateFollowUp = async (values) => {
        console.log('Submit follow-up update:', values);
        if (values.follow_up_date) {
            values.follow_up_date = values.follow_up_date.format('YYYY-MM-DD HH:mm:ss');
        }

        try {
            const response = await addLeadInteraction(selectedLeadId, {
                ...values,
                interaction_type: 'phone_call',  // Ensure this is dynamically set as needed
            });
            console.log('Update success:', response);

            messageContext.success("Follow up updated successfully.");
            setUpdateModalVisible(false); // Close the modal only on success
            refreshData();  // Refresh the entire data including lead details
            fetchLeadDetailsById(selectedLeadId); // Specifically fetch the updated lead details
            setRefreshInteractions(true);
        } catch (error) {
            console.error('Failed to update follow-up:', error);
            messageContext.error('Failed to update follow-up: ' + (error.response?.data.error || 'Unknown error'));
            // Do not close the modal, allow the user to make changes and submit again
        }
    };


    // Toggle view mode to show the lead registration form
    const handleCreateNewLead = () => {
        setViewMode('create');
    };

    // Callback for when the form wants to go back to the list view
    const handleBackToList = () => {
        setViewMode('list');
    };

    return (
        <div style={{paddingRight: '15px'}}>
            {viewMode === 'list' ? (
                <>
                    {/* Filter Inputs */}
                    <Row gutter={[16, 16]}>
                        <Col xs={24} sm={12} md={8} lg={8}>
                            <Input
                                placeholder="Search by Customer Name"
                                value={userFilters.customer_name}
                                onChange={e => handleFilterChange('customer_name', e.target.value)}
                                style={{width: '100%'}}
                            />
                        </Col>
                        <Col xs={24} sm={12} md={8} lg={8}>
                            <Input
                                placeholder="Search by Mobile Number"
                                value={userFilters.mobile_number}
                                onChange={e => handleFilterChange('mobile_number', e.target.value)}
                                style={{width: '100%'}}
                            />
                        </Col>
                        <Button type="primary" icon={<PlusOutlined/>} onClick={handleCreateNewLead}>
                            Create New Lead
                        </Button>
                    </Row>

                    {/* Second row of filters: Status, Source, and Assigned To */}
                    <Row gutter={[16, 16]} style={{marginTop: 16}}>
                        <Col xs={24} sm={12} md={6} lg={6}>
                            <Select
                                placeholder="Select Status"
                                value={userFilters.status}
                                onChange={value => handleFilterChange('status', value)}
                                style={{width: '100%'}}
                                allowClear
                            >
                                <Option value="new">New</Option>
                                <Option value="contacted">Contacted</Option>
                                <Option value="qualified">Qualified</Option>
                                <Option value="proposal">Proposal Sent</Option>
                                <Option value="converted">Converted</Option>
                                <Option value="lost">Lost</Option>
                            </Select>
                        </Col>
                        <Col xs={24} sm={12} md={6} lg={6}>
                            <Select
                                placeholder="Select Source"
                                value={userFilters.source}
                                onChange={value => handleFilterChange('source', value)}
                                style={{width: '100%'}}
                                allowClear
                            >
                                {/* Replace these static options with dynamic options from your store/API */}
                                <Option value="1">Source One</Option>
                                <Option value="2">Source Two</Option>
                            </Select>
                        </Col>
                        <Col xs={24} sm={12} md={6} lg={6}>
                            <Select
                                placeholder="Select Assigned To"
                                value={userFilters.assigned_to}
                                onChange={value => handleFilterChange('assigned_to', value)}
                                style={{width: '100%'}}
                                allowClear
                            >
                                {/* Replace these static options with dynamic options, e.g., list of users */}
                                <Option value="1">User One</Option>
                                <Option value="2">User Two</Option>
                            </Select>
                        </Col>
                        <Col xs={24} sm={12} md={6} lg={6}>
                            <Space>
                                <Button type="primary" icon={<SearchOutlined/>} onClick={handleApplyFilters}>
                                    Apply Filter
                                </Button>
                                <Button icon={<ClearOutlined/>} onClick={handleClearFilters} danger>
                                    Clear Filter
                                </Button>
                            </Space>
                        </Col>
                    </Row>

                    {/* Leads Table */}
                    <LeadsTable
                        data={leads}
                        loading={loading}
                        pagination={{
                            current: pagination.current,
                            pageSize: pagination.pageSize,
                            total: pagination.total,
                        }}
                        onPageChange={handlePageChange}
                        onViewLead={(lead) => openDrawer(lead.id)}
                    />

                    {/* Lead Details Drawer */}
                    <LeadDetailsDrawer
                        visible={drawerVisible}
                        leadId={selectedLeadId}
                        onClose={closeDrawer}
                        onEdit={() => console.log('Edit lead')}
                        onUpdateFollowUp={handleOpenUpdateModal}
                        refreshInteractions={refreshInteractions}
                        refreshData={refreshData}
                        setSelectedLead={setSelectedLead}
                    />
                    <UpdateFollowUpModal
                        visible={updateModalVisible}
                        onClose={handleCloseUpdateModal}
                        onSubmit={(values) => {
                            handleUpdateFollowUp(values);
                            refreshData(); // Ensure this is called after handling the update
                        }}
                        initialValues={{status: selectedLead?.status, details: selectedLead?.details}}
                    />
                </>
            ) : (
                // Render the Lead Registration Form with a back button
                <LeadRegistrationForm onBack={handleBackToList} refreshData={refreshData}/>
            )}
        </div>
    );
};

export default LeadsList;
