// MessageContext.js
import React, { createContext, useContext } from 'react';
import { message } from 'antd';

const MessageContext = createContext();

export const MessageProvider = ({ children }) => {
  // Use the antd message hook
  const [messageApi, contextHolder] = message.useMessage();

  // Define your centralized functions
  const showMessage = {
    success: (content, duration = 3) => messageApi.success({ content, duration }),
    error: (content, duration = 3) => messageApi.error({ content, duration }),
    warning: (content, duration = 3) => messageApi.warning({ content, duration }),
    info: (content, duration = 3) => messageApi.info({ content, duration }),
  };

  return (
    <MessageContext.Provider value={showMessage}>
      {contextHolder}
      {children}
    </MessageContext.Provider>
  );
};

// Custom hook for using the message context
export const useMessageContext = () => {
  const context = useContext(MessageContext);
  if (!context) {
    throw new Error('useMessageContext must be used within a MessageProvider');
  }
  return context;
};
