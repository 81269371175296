import React, {useEffect, useState} from 'react';
import {Alert, Card, Col, Progress, Row, Spin, Statistic} from 'antd';
import {fetchLeadsSummary} from "./leadsDashboardApi";

const LeadsSummary = () => {
    const [data, setData] = useState({
        total_leads: 0,
        open_leads: 0,
        conversion_ratio: 0,
        converted_leads: 0,
        lost_leads: 0,
    });
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        setLoading(true);
        fetchLeadsSummary().then(response => {
            setData(response.data);
            setLoading(false);
        }).catch(err => {
            setError(err.toString());
            setLoading(false);
        });
    }, []);

    const getProgressColor = (percentage, type) => {
        if (type === 'conversion') {
            return percentage > 70 ? '#52c41a' : percentage > 50 ? '#faad14' : '#cf1322';
        } else if (type === 'lost') {
            return percentage > 50 ? '#cf1322' : percentage > 35 ? '#faad14' : '#52c41a';
        }
    };

    const conversionColor = getProgressColor(data.conversion_ratio, 'conversion');
    const lostColor = getProgressColor((100 - data.conversion_ratio), 'lost');

    if (loading) return <Spin tip="Loading..."/>;
    if (error) return <Alert message="Error" description={error} type="error" showIcon/>;

    return (
        <Row gutter={[16, 24]} justify="center">
            <Col xs={24} sm={12}>
                <Card style={{height: '135px'}}>
                    <Statistic
                        title="Open Leads"
                        value={data.open_leads}
                        valueStyle={{color: '#108ee9'}}
                    />
                    {/*<Progress percent={((data.open_leads / data.total_leads) * 100).toFixed(2)} status="active" strokeColor={conversionColor} />*/}
                </Card>
            </Col>
            <Col xs={24} sm={12}>
                <Card>
                    <Statistic
                        title="Conversion Ratio"
                        value={`${data.conversion_ratio.toFixed(2)}%`}
                        valueStyle={{color: conversionColor}}
                    />
                    <Progress percent={data.conversion_ratio.toFixed(2)} status="active" strokeColor={conversionColor}/>
                </Card>
            </Col>
            <Col xs={24} sm={12}>
                <Card>
                    <Statistic
                        title="Converted Leads"
                        value={data.converted_leads}
                        valueStyle={{color: conversionColor}}
                    />
                    <Progress percent={data.conversion_ratio.toFixed(2)} status="active" strokeColor={conversionColor}/>
                </Card>
            </Col>
            <Col xs={24} sm={12}>
                <Card>
                    <Statistic
                        title="Lost Leads"
                        value={data.lost_leads}
                        valueStyle={{color: lostColor}}
                    />
                    <Progress
                        percent={
                            data.total_leads > 0
                                ? ((data.lost_leads / data.total_leads) * 100).toFixed(2)
                                : 0
                        }
                        status="active"
                        strokeColor={lostColor}
                    />

                </Card>
            </Col>
        </Row>
    );
};

export default LeadsSummary;
