import axios from 'axios';
import config from '../../config';
import { getAuthToken, getRoleBasedFilters } from '../../utils/authUtils';

export const fetchCustomersApi = async (params) => {
    console.log("Fetch Customers API Called");
    const token = getAuthToken();
    // include your auth tokens and any necessary headers
    try {
        const response = await axios.get(`${config.inxOmsApiUrl}/b2c_customers/api/customers/`, {
            headers: { Authorization: `Token ${token}` },
            params,
        });

        return response.data;
    } catch (error) {
        throw error;
    }
};


export const searchCustomerApi = async (query) => {
    const token = getAuthToken();
    const roleFilters = getRoleBasedFilters();

    try {
        const response = await axios.get(`${config.inxOmsApiUrl}/b2c_customers/api/customers/search/`, {
            headers: {
                Authorization: `Token ${token}`,
            },
            params: {
                q: query,
                ...roleFilters,
            },
        });
        return response.data;
    } catch (error) {
        console.error('Error searching customer:', error);
        if (error.response && error.response.data) {
            throw new Error(error.response.data);
        }
        throw new Error('Failed to search for customers');
    }
};


// Function to register a new customer
export const registerCustomerApi = async (customerData) => {
    const token = getAuthToken();
    try {
        const response = await axios.post(`${config.inxOmsApiUrl}/b2c_customers/api/customers/register/`, customerData, {
            headers: { Authorization: `Token ${token}` }
        });
        console.log(response);
        return response.data;
    } catch (error) {
        console.error('Error registering customer:', error);
        throw error.response ? error.response.data : error;
    }
};