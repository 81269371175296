import React, { useState, useEffect } from 'react';
import {Table, Spin, Alert, Empty} from 'antd';
import { fetchCampaignPerformance } from './dashboardApi';

const CampaignsDashboard = () => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true); // Initialize as true to show loader immediately
  const [error, setError] = useState(null);

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    showTotal: (total, range) => `Showing ${range[0]}-${range[1]} of ${total} items`
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch campaign performance and pass parameter directly
        const response = await fetchCampaignPerformance({ showActive: true });
        setData(response.data.campaigns); // Ensure proper path based on your actual API response
        setIsLoading(false);
      } catch (error) {
        setError('Failed to load data: ' + error.message); // More informative error message
        setIsLoading(false);
      }
    };

    fetchData();
  }, []); // Dependency array left empty to mimic componentDidMount

  const columns = [
    { title: 'Campaign Name', dataIndex: 'campaign__name', key: 'name' },
      { title: 'Success Rate', dataIndex: 'success_rate', key: 'successRate', render: rate => `${(rate * 100).toFixed(2)}%` },
    { title: 'Response Rate', dataIndex: 'response_rate', key: 'responseRate', render: rate => `${(rate * 100).toFixed(2)}%` },
      { title: 'Success', dataIndex: 'total_success', key: 'success' },
    { title: 'Responses', dataIndex: 'responses_received', key: 'responses' },
    { title: 'Attempted', dataIndex: 'attempted', key: 'attempted' },
    { title: 'Sent', dataIndex: 'total_sent', key: 'sent' },
    { title: 'Failed', dataIndex: 'total_failed', key: 'failed' },


  ];

  if (isLoading) return <Spin size="large" />;
  if (error) return <Alert message="Error" description={error} type="error" showIcon />;

  return (
    <Table
      columns={columns}
      dataSource={data}
      rowKey="campaign__id"
      pagination={{
        ...pagination,
        showTotal: (total, range) => `Showing ${range[0]}-${range[1]} of ${total} items`,
        onChange: (page, pageSize) => setPagination({ ...pagination, current: page, pageSize })
      }}
      loading={isLoading}
      size="small"
      scroll={{ x: 'max-content' }}
      locale={{
        emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No records available."/>
      }}
    />
  );
};

export default CampaignsDashboard;
