import React, { useState, useEffect } from "react";
import { Modal, Select, Button } from "antd";
import {fetchUsers, updateLead, updateLeadAssignee} from "./crmApis";
import {useMessageContext} from "../../components/MessageContext"; // API functions

const { Option } = Select;

const UpdateAssigneeModal = ({ visible, lead, onClose, onSuccess }) => {
  const messageContext = useMessageContext();
  const [users, setUsers] = useState([]);
  const [selectedAssignee, setSelectedAssignee] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (visible) {
      fetchUsers().then(setUsers).catch(console.error);
    }
  }, [visible]);

  const handleSubmit = async () => {
    if (!selectedAssignee) return;
    setLoading(true);
    try {
      await updateLeadAssignee(lead.id, selectedAssignee ); // Call API
      messageContext.success("Assignee Updated Successfully.");
      onSuccess(); // Refresh data
      onClose(); // Close modal
    } catch (error) {
      console.error("Update failed:", error);
      messageContext.error("Update failed:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      title="Update Assignee"
      open={visible}
      onCancel={onClose}
      footer={[
        <Button key="cancel" onClick={onClose}>Cancel</Button>,
        <Button key="submit" type="primary" loading={loading} onClick={handleSubmit}>Update</Button>,
      ]}
    >
      <Select
        placeholder="Select Assignee"
        style={{ width: "100%" }}
        onChange={setSelectedAssignee}
      >
        {users.map(user => (
          <Option key={user.id} value={user.id}>{user.full_name}</Option>
        ))}
      </Select>
    </Modal>
  );
};

export default UpdateAssigneeModal;
