import React, { useEffect, useState } from 'react';
import { Button, Form, Input, Select, Modal, notification, Switch } from 'antd';
import { createCampaignContent, updateCampaignContent, fetchMarketingCampaigns } from './crmApis';

const { Option } = Select;
const { TextArea } = Input;

const CampaignContentFormModal = ({ visible, onClose, content, refreshContents }) => {
    const [form] = Form.useForm();
    const [campaigns, setCampaigns] = useState([]);

    useEffect(() => {
        if (visible) {
            loadCampaigns();
            form.setFieldsValue({
                ...content,
                is_personalized: content ? content.is_personalized : false
            });
        }
    }, [form, content, visible]);

    const loadCampaigns = async () => {
        try {
            const data = await fetchMarketingCampaigns();
            setCampaigns(data.results || []);
        } catch (error) {
            notification.error({
                message: 'Failed to load campaigns',
                description: error.message
            });
        }
    };

    const handleSubmit = async (values) => {
        try {
            if (content) {
                await updateCampaignContent({ ...values, id: content.id });
                notification.success({ message: 'Content updated successfully!' });
            } else {
                await createCampaignContent(values);
                notification.success({ message: 'Content created successfully!' });
            }
            refreshContents();
            onClose();
        } catch (error) {
            notification.error({
                message: 'Operation failed',
                description: error.response ? error.response.data : 'Unknown error'
            });
        }
    };

    return (
        <Modal
            title={content ? "Edit Campaign Content" : "Create New Campaign Content"}
            open={visible}
            onOk={() => form.submit()}
            onCancel={onClose}
            footer={[
                <Button key="back" onClick={onClose}>Cancel</Button>,
                <Button key="submit" type="primary" onClick={() => form.submit()}>{content ? "Update" : "Create"}</Button>,
            ]}
        >
            <Form form={form} layout="vertical" onFinish={handleSubmit}>
                <Form.Item name="title" label="Title" rules={[{ required: true, message: 'Please input the title!' }]}>
                    <Input />
                </Form.Item>
                <Form.Item name="campaign" label="Campaign" rules={[{ required: true, message: 'Please select a campaign!' }]}>
                    <Select placeholder="Select a campaign">
                        {campaigns.map(campaign => (
                            <Option key={campaign.id} value={campaign.id}>{campaign.name}</Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item name="message_type" label="Message Type" rules={[{ required: true, message: 'Please select a message type!' }]}>
                    <Select placeholder="Select a message type">
                        <Option value="email">Email</Option>
                        <Option value="whatsapp">WhatsApp</Option>
                        <Option value="sms">SMS</Option>
                        <Option value="social_media">Social Media</Option>
                    </Select>
                </Form.Item>
                <Form.Item name="content" label="Content" rules={[{ required: true, message: 'Please input the content!' }]}>
                    <TextArea rows={4} />
                </Form.Item>
                <Form.Item
                    name="template_id"
                    label="Template ID"
                    rules={[{ required: false, message: 'Please input the template ID if available!' }]}
                >
                    <Input placeholder="Optional" />
                </Form.Item>
                <Form.Item
                    name="is_personalized"
                    label="Is Personalized"
                    valuePropName="checked"
                >
                    <Switch />
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default CampaignContentFormModal;
