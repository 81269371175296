import axios from 'axios';
import config from "../../config";
import {getAuthToken, getRoleBasedFilters} from "../../utils/authUtils";

const token = getAuthToken();

/**
 * Fetch serial number details from the API.
 * @param {string} serialNumber - The serial number to fetch.
 * @returns {Promise<object>} - The serial number details.
 * @throws Will throw an error if the request fails or the serial number does not exist.
 */
export const fetchSerialNumberDetails = async (serialNumber) => {
    const token = getAuthToken();
    try {
        console.log(`${config.inxOmsApiUrl}`);
        const response = await axios.get(
            `${config.inxOmsApiUrl}/inventory/api/serial-numbers/${serialNumber}/`,
            {
            headers: {Authorization: `Token ${token}`,
            },
        });
        console.log("Reponse:", response);
        return response.data;
    } catch (error) {
        if (error.response && error.response.status === 404) {
            throw new Error(`Serial number ${serialNumber} does not exist.`);
        }
        throw new Error(`Failed to fetch serial number ${serialNumber}.`);
    }
};

// Fetch stocks with pagination and filters
export const fetchStocksApi = async (
    params
) => {
    const token = getAuthToken();

    try {
        const response = await axios.get(`${config.inxOmsApiUrl}/inventory/api/stocks/`, {
            headers: { Authorization: `Token ${token}` },
            params,
        });
        return response.data;
    } catch (error) {
        if (error.response) {
            throw new('Error response from API:', error.response.data);
        }
        throw new Error('Failed to load stocks');
    }
};



export const fetchInventoryInboundApi = async (page = 1, searchTerm = '') => {

    try {
        const response = await axios.get(`${config.inxOmsApiUrl}/inventory/api/inventory-inbounds/`, {
             headers: { Authorization: `Token ${token}` },
            params: {

                page: page,
                search: searchTerm  // Adjust parameter name according to your API's expected query params
            },

        });
        return response.data; // Assuming the response has a standardized format
    } catch (error) {
        console.error('Failed to fetch inventory inbound records:', error);
        throw error;
    }
};

export const fetchSerialNumbersApi = async (params) => {
  // 'params' is a plain object with page, page_size, status, serial_number, etc.
  const token = getAuthToken();
  try {
    const response = await axios.get(`${config.inxOmsApiUrl}/inventory/api/serial-numbers/`, {
      headers: { Authorization: `Token ${token}` },
      params, // pass the object
    });
    return response.data;
  } catch (error) {
    // handle error
    throw error;
  }
};

