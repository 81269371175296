import React, {useCallback, useEffect, useState} from 'react';
import {Button, Col, Empty, Input, Row, Select, Table} from 'antd';
import EventDetailsDrawer from './EventDetailsDrawer';
import {fetchEvents} from "./crmApis";
import {formatDateTime} from "../../utils/dateUtils";
import EventFormModal from "./EventFormModal";

const {Option} = Select;

const EventsList = () => {
    const [events, setEvents] = useState([]);
    const [selectedEvent, setSelectedEvent] = useState(null);
    const [drawerVisible, setDrawerVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const [filters, setFilters] = useState({status: '', campaign: ''});
    const [pagination, setPagination] = useState({current: 1, pageSize: 10, total: 0});

    const [createFormVisible, setCreateFormVisible] = useState(false);

    useEffect(() => {
        loadEvents();
    }, [pagination.current, pagination.pageSize, filters]);

    const loadEvents = useCallback(async () => {
        setLoading(true);
        try {
            const data = await fetchEvents({
                ...filters,
                page: pagination.current,
                pageSize: pagination.pageSize
            });
            setEvents(data.results);
            setPagination((prev) => ({...prev, total: data.total}));
        } catch (error) {
            console.error('Failed to load events:', error);
        } finally {
            setLoading(false);
        }
    }, [filters, pagination.current, pagination.pageSize]);

    const handlePageChange = (page, pageSize) => {
        setPagination({current: page, pageSize});
    };

    const handleFilterChange = (type) => (value) => {
        setFilters((prev) => ({...prev, [type]: value}));
        setPagination((prev) => ({...prev, current: 1})); // Reset pagination when filters change
    };

    const columns = [
        {title: '#', dataIndex: 'id', key: 'id'},
        {title: 'Name', dataIndex: 'event_name', key: 'event_name'},
        {title: 'Content', dataIndex: 'content_name', key: 'content_name'},
        {title: 'Campaign', dataIndex: 'campaign_name', key: 'campaign_name'},

        {title: 'Status', dataIndex: 'status', key: 'status'},
        {
            title: 'Scheduled Date',
            dataIndex: 'scheduled_date',
            key: 'scheduled_date',
            render: (value) => (value ? formatDateTime(value) : '—'),
        },
        {
            title: 'Executed Date',
            dataIndex: 'executed_date',
            key: 'executed_date',
            render: (value) => (value ? formatDateTime(value) : '—'),
        },
    ];

    const handleEditEvent = (event) => {
        setSelectedEvent(event);
        setDrawerVisible(false);
        setCreateFormVisible(true);
    };

    // Custom pagination summary
    const showPaginationTotal = (total, range) => {
        return `Showing ${range[0]}-${range[1]} of ${total} records`;
    };

    return (
        <div style={{padding: 24}}>
            <Row gutter={[16, 16]}>
                <Col span={8}>
                    <Select
                        placeholder="Filter by Status"
                        style={{width: '100%'}}
                        onChange={handleFilterChange('status')}
                        allowClear
                    >
                        <Option value="active">Active</Option>
                        <Option value="draft">Draft</Option>
                        <Option value="completed">Completed</Option>
                        <Option value="scheduled">Scheduled</Option>
                    </Select>
                </Col>
                <Col span={8}>
                    <Input
                        placeholder="Search by Campaign Name"
                        onChange={(e) => handleFilterChange('campaign')(e.target.value)}
                    />
                </Col>
                <Col span={8}>
                    <Button type="primary" onClick={() => setCreateFormVisible(true)}>
                        Create New Event
                    </Button>
                </Col>
            </Row>
            <Table
                columns={columns}
                dataSource={events}
                rowKey="id"
                loading={loading}
                pagination={{
                    ...pagination,
                    showTotal: showPaginationTotal,
                    onPageChange: handlePageChange,
                }}
                size="small"
                scroll={{x: 'max-content'}}
                locale={{
                    emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No records available."/>
                }}
                onRow={(record) => ({
                    onClick: () => {
                        setSelectedEvent(record);
                        setDrawerVisible(true);
                    }
                })}
            />

            <EventDetailsDrawer
                visible={drawerVisible}
                onClose={() => setDrawerVisible(false)}
                event={selectedEvent}
                 onEditEvent={handleEditEvent}
                refreshEvents={loadEvents}
            />
            <EventFormModal
                visible={createFormVisible}
                onClose={() => setCreateFormVisible(false)}
                event={selectedEvent}
                refreshEvents={loadEvents}
            />
        </div>
    );
};

export default EventsList;
