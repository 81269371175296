import React, { useState } from "react";
import { Modal, Form, Input, InputNumber, Button } from "antd";
import { updateLead } from "./crmApis";
import {useMessageContext} from "../../components/MessageContext"; // API call

const LeadEditModal = ({ visible, lead, onClose, onSuccess }) => {
  const messageContext = useMessageContext();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  // Populate form with existing data
  React.useEffect(() => {
    if (lead) {
      if (form) {
        if (!visible) return;
        console.log("Form is connected, setting up default values");
        form.resetFields();
        form.setFieldsValue({
          email: lead.email,
          requirements: lead.requirements,
          estimated_revenue: lead.estimated_revenue,
        });
      }
    }
  }, [lead, form]);

  const handleSubmit = async (values) => {
    setLoading(true);
    try {
      await updateLead(lead.id, values); // Call API
      messageContext.success("Successfully updated lead");
      onSuccess(); // Refresh data
      onClose(); // Close modal
    } catch (error) {
      console.error("Update failed:", error);
      messageContext.error("Update failed:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      title="Edit Lead"
      open={visible}
      onCancel={onClose}
      footer={[
        <Button key="cancel" onClick={onClose}>Cancel</Button>,
        <Button key="submit" type="primary" loading={loading} onClick={() => form.submit()}>Update</Button>,
      ]}
    >
      <Form form={form} layout="vertical" onFinish={handleSubmit}>
        <Form.Item label="Email" name="email">
          <Input placeholder="Enter email" />
        </Form.Item>
        <Form.Item label="Requirements" name="requirements">
          <Input.TextArea placeholder="Enter requirements" rows={3} />
        </Form.Item>
        <Form.Item label="Estimated Revenue" name="estimated_revenue">
          <InputNumber min={0} style={{ width: "100%" }} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default LeadEditModal;
